import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'gwSafeHtml' })

/** Pipe is used to tag a string as "Safe HTML" */
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitized: DomSanitizer) { }

	/**
	 * @param value String containing raw HTML
	 */
	transform(value: string): SafeHtml {
		return this.sanitized.bypassSecurityTrustHtml(value);
	}
}
