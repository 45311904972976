import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { gameGroupNameMap } from '../shared/mapping';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { AppConfigService, GameService, PastNumberIndicator } from '../shared';

@Component({
	selector: 'gw-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewChecked {
	gameGroupCode: string;
	gameGroupName: string;

	fragment: string;
	yOffsetHeader: any;
	shouldScroll = false;

	gameServiceObservable: Observable<PastNumberIndicator[]>;
	pastNumberIndicators: PastNumberIndicator[] = [];

	constructor(private router: Router, private gameService: GameService, private route: ActivatedRoute, private appConfigService: AppConfigService) { }

	ngOnInit(): void {
		// unsubscribe on scroll?
		this.route.fragment.subscribe(fragment => { this.fragment = fragment; this.shouldScroll = true });
		this.gameServiceObservable = this.gameService.getPastNumberTrends();
		this.gameServiceObservable.subscribe(data => this.pastNumberIndicators = data);
		this.gameGroupCode = this.appConfigService.gameGroupCode;
		this.gameGroupName = gameGroupNameMap[this.gameGroupCode];
	}

	ngAfterViewChecked(): void {
		try {
			this.yOffsetHeader = document.querySelector('#header').scrollHeight + 30;
			document.querySelectorAll('.nav > a').forEach(x => {
				x.classList.remove('selected');
			});

			if (this.fragment) {
				const element = document.querySelector('#' + this.fragment);
				const y = element.getBoundingClientRect().top + window.pageYOffset - this.yOffsetHeader;
				
				if(this.shouldScroll) {
					window.scrollTo({ top: y, behavior: 'smooth' });
					this.shouldScroll = false;
				}
				document.querySelector(`[fragment="${this.fragment}"]`).classList.add('selected');
			} else {
				// default highlight jackpot
				// document.querySelector('[fragment="jackpot"]').classList.add('selected');
			}
		} catch (e) { }
	}

	navigateHome() {
		this.router.navigate(['.']);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
}
