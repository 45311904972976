import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges,
	ContentChild, TemplateRef, ElementRef, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';

/**
 * Contains logic to handle a dropdown list
 */
@Component({
	selector: 'gw-dropdown',
	styleUrls: [
		'./dropdown.component.scss'
	],
	templateUrl: './dropdown.component.html'
})

export class DropdownComponent implements OnInit, AfterViewInit, OnChanges {
	/** Sets the text to be shown when no items are selected */
	@Input() noSelectionText = 'Select';

	/** The list of items to be shown. Each item will be a separate list item */
	@Input() list: any[] = [];

	/**
	 * labelProp represents the object property that will be used to display the item in the button.
	 * Note: Property is ignored when a custom template for the list items is defined
	 */
	@Input() labelProp = 'itemName';

	/** Sets whether multiple items can be selected */
	@Input() multipleSelection = false;

	/** List separator used to separate item names when multiple items can be selected */
	@Input() multipleItemsSeparator = ', ';

	/** Whether a custom icon will be used or not */
	@Input() customSelectorIcon = false;

	/** If `multipleSelection` is enabled, this property sets the minimum of items that can be selected. */
	@Input() minSelectedItems = 0;

	/** Whether scrollbar should be shown or not. */
	@Input() showScrollbar = false;

	/** If `showScrollbar` is `true`, the list needs to have a defined height. */
	@Input() listHeight: number;

	/** Array with the selected items. Note this property supports two-way data binding. */
	@Input() listSelection: any[] = [];

	/**
	 * Emitted whenever an item is selected. Note: Since listSelection supports two-way data binding, it is optional to use this function.
	 * If this function is used make sure to update the selection array manually.
	 */
	@Output() listSelectionChange: EventEmitter<any[]> = new EventEmitter<any[]>();

	/** Sets whether a dropdown is disabled or not */
	@Input() isDisabled = false;

	/** Template reference of the selected item */
	@ContentChild('selectedItemTemplate', { static: false }) selectedItemTemplate: TemplateRef<ElementRef>;

	/** Template reference of the dropdown list item */
	@ContentChild('itemsTemplate', { static: false }) itemsTemplate: TemplateRef<ElementRef>;

	/** Element reference of the selector div */
	@ViewChild('selectorDiv', { static: false }) private selectorDiv: ElementRef;

	/** Element reference of the dropdown div */
	@ViewChild('dropdownDiv', { static: false }) private dropdownDiv: ElementRef;

	/** Element reference of the dropdown with scroll div */
	@ViewChild('dropdownScrollDiv', { static: false }) private dropdownScrollDiv: ElementRef;

	/** Selector HTML Element */
	private selectorElement: HTMLElement;

	/** Dropdown HTML Element
	 *
	 * Value depends on the `showScrollbar`, if `false` it will save the `dropdownDiv` element,
	 * else if `true` it will save the `dropdownDiv` element
	 */
	private dropdownElement: HTMLElement;

	/** Text to be shown in the selected item */
	selectorText: string;

	/** Whether dropdown list is opened or not */
	isOpened = false;

	/** Height of list in pixels */
	listHeightPX: string;

	constructor(
		private changeDetectorRef: ChangeDetectorRef
	) { }

	ngOnInit() {
		this.selectorText = this.noSelectionText;

		if (this.listSelection.length > 0) {
			this.updateSelectorText();
		}

		if (this.showScrollbar) {
			if (this.listHeight === undefined) {
				// TEXT TO TRANSLATE
				throw new console.error('Specify Dropdown List Height');
			} else {
				this.listHeightPX = `${this.listHeight}px`;
			}
		}
	}

	ngAfterViewInit() {
		this.selectorElement = this.selectorDiv.nativeElement;

		this.dropdownElement = (this.showScrollbar) ? this.dropdownScrollDiv.nativeElement : this.dropdownDiv.nativeElement;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['listSelection'] !== undefined) {
			const change = changes['listSelection'];

			if (!change.isFirstChange()) {
				const previousObj = JSON.stringify(change.previousValue);
				const currentObj = JSON.stringify(change.currentValue);

				if (previousObj !== currentObj) {
					this.listSelectionChange.emit(this.listSelection);
					this.updateSelectorText();
				}
			}
		}
	}

	/** Shows/hide dropdown list */
	toggleList() {
		if (!this.isDisabled) {
			this.isOpened = !this.isOpened;
			//console.log(this.isOpened, 'IS OPEN');
		}

		if (this.isOpened) {
			this.changeDetectorRef.detectChanges();
			this.openDownwardsOrUpwards();
		}

	}

	/**
	 * Function that is run whenever a list item is selected/unselected
	 * @param item Selected/unselected item
	 * @param emitData Whether `listSelection` data should be emitted or no
	 */
	toggleSelection(item: any) {
		if (this.isDisabled) { return; }
		const index = this.listSelection.findIndex(temp => temp === item);

		if (this.multipleSelection) {
			if (index === -1) {
				this.listSelection.push(item);
			} else {
				if (this.listSelection.length > this.minSelectedItems) {
					this.listSelection.splice(index, 1);
				}
			}
		} else {
			this.listSelection.splice(index, 1, item);
		}

		this.listSelection = this.listSelection.slice();
		this.updateSelectorText();

		this.listSelectionChange.emit(this.listSelection);

		if (!this.multipleSelection) {
			if (this.isOpened) {
				this.toggleList();
			}
		}
	}

	/**
	 * Returns whether a list item is selected or not
	 * @param item Selected/unselected item
	 * @returns Whether the item is selected or not
	 */
	isSelected(item: any): boolean {
		return this.listSelection.findIndex(temp => temp === item) !== -1;
	}

	/**
	 * Function to remove all selected items from the selection array
	 * @param emitData Whether `listSelection` data should be emitted or not
	 */
	clearSelection() {
		if (this.isDisabled) { return; }
		this.listSelection = [];

		this.listSelection = this.listSelection.slice();
		this.updateSelectorText();

		this.listSelectionChange.emit(this.listSelection);
	}

	/** Hide list when dropdown list is opened and click event is on other element */
	onClickedOutside() {
		if (this.isOpened) {
			this.toggleList();
		}
	}

	private updateSelectorText() {
		if (this.listSelection.length > 0) {
			this.selectorText = this.listSelection.map(temp => {
				return temp[this.labelProp];
			}).join(`${this.multipleItemsSeparator}`);
		} else {
			this.selectorText = this.noSelectionText;
		}
	}

	private openDownwardsOrUpwards() {
		const windowHeight = window.innerHeight;

		const dropdownHeight = this.dropdownElement.getBoundingClientRect().height;
		const dropdownPositionY = this.selectorElement.getBoundingClientRect().bottom;

		if (dropdownHeight + dropdownPositionY > windowHeight) {
			this.dropdownElement.style.bottom = `${this.selectorElement.getBoundingClientRect().height}px`;
			this.dropdownElement.style.flexDirection = 'column-reverse';
		} else {
			this.dropdownElement.style.bottom = 'auto';
			this.dropdownElement.style.flexDirection = 'column';
		}
	}
}
