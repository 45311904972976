import { Component, OnInit, Input } from '@angular/core';
import { PastNumberIndicator, DrawnBoardNumber } from '../shared/models';
import { Router } from '@angular/router';
import { GameService } from '../shared/services';
import { Utilities } from '../shared/service-utils';

@Component({
	selector: 'gw-statistics',
	templateUrl: './statistics.component.html',
	styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
	@Input('statistics') inputStatistics: PastNumberIndicator[] = [];

	currentStatistics: PastNumberIndicator[] = [];
	drawnNumberFrequencies: DrawnBoardNumber[];
	viewLink = false;
	isCardGame = false;
	years: any[] = [];
	selectedYear: any[] = [];

	constructor(
		private gameService: GameService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.isCardGame = this.gameService.isCardGame;
		if (this.inputStatistics.length > 0) {
			this.currentStatistics = [...this.inputStatistics];
			this.viewLink = true;
			this.getBoards();
		} else {
			this.getPastNumberIndicatorsFromServer();
			this.getDrawnNumbersFrequencyFromServer(new Date().getFullYear());

			const first = 2018;
			const now = new Date().getFullYear();

			for (let i = now; i >= first; i--) {
				this.years.push({
					year: i
				});
			}

			this.selectedYear = [{
				year: 2020
			}];
		}
	}

	getPastNumberIndicatorsFromServer() {
		this.gameService.getPastNumberTrends().subscribe(
			res => {
				this.currentStatistics = res;
				this.getBoards();
			});
	}

	yearChange(selectedYear: any[]) {
		this.selectedYear = selectedYear;

		this.getDrawnNumbersFrequencyFromServer(+selectedYear[0].year);
	}

	getDrawnNumbersFrequencyFromServer(year: number) {
		this.gameService.getDrawnNumberFrequency(year).subscribe(
			res => {
				this.drawnNumberFrequencies = res;

				this.drawnNumberFrequencies.forEach((item) => {
					item.drawnNumbersFrequency.sort((a, b) => (a.number > b.number) ? 1 : -1);
				});
			});
	}

	getBoards() {
		this.currentStatistics.forEach(item => {
			item.boards = Utilities.formatDrawResults(item.numbers);
		});
	}

	navigateToStatistics() {
		this.router.navigate(['/statistics']);
	}
}
