import { Injectable } from '@angular/core';

@Injectable()
export class AppLangService {
	private currentSelectedLanguage = 'en';
	private currentSelectedLocaleID = 'en-GB';

	get selectedLanguage(): string {
		return this.currentSelectedLanguage;
	}

	set selectedLanguage(newSelectedLanguage: string) {
		localStorage.setItem('appLang', newSelectedLanguage);
		this.currentSelectedLanguage = newSelectedLanguage;
	}

	get selectedLocaleID(): string {
		return this.currentSelectedLocaleID;
	}

	set selectedLocaleID(newSelectedLocaleID: string) {
		this.currentSelectedLocaleID = newSelectedLocaleID;
	}
}
