import { Component, OnInit } from '@angular/core';
import { AppLoaderService } from '../shared';

@Component({
	selector: 'gw-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

	constructor(
		private appLoaderService: AppLoaderService
	) { }

	ngOnInit(): void {
		this.appLoaderService.isLoading = false;
	}
}
