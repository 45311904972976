import { trigger, style, transition, animate } from '@angular/animations';

export const drawAnimationCoutdownComponentAnimations: any = [
	trigger('fade', [
		transition(':enter', [
			style({ opacity: 0 }),
			animate('250ms', style({ opacity: 1 }))
		])
	])
];
