import { Dictionary } from '../shared/models/dictionary';

export const GameDescriptionContentConfig = new Dictionary<string>();

GameDescriptionContentConfig.add('eurojackpot', 'euro-jackpot');
GameDescriptionContentConfig.add('euromillions', 'euro-millions');
GameDescriptionContentConfig.add('megamillions', 'mega-millions');
GameDescriptionContentConfig.add('mega-sena', 'mega-sena');
GameDescriptionContentConfig.add('powerball', 'power-ball');
GameDescriptionContentConfig.add('lotto-6aus49', 'lotto-6-49');
GameDescriptionContentConfig.add('heromillions', 'hero-millions');
GameDescriptionContentConfig.add('thedailyhero', 'the-daily-hero');
GameDescriptionContentConfig.add('thebighero', 'the-big-hero');
GameDescriptionContentConfig.add('lotto-6-36', 'lotto-6-36');
GameDescriptionContentConfig.add('lottorush', 'lotto-rush');
GameDescriptionContentConfig.add('lottohero', 'lotto-hero');
GameDescriptionContentConfig.add('lottohero-5minute', 'lotto-hero');
