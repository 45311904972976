<h1>{{ 'navigation.game-rules' | translate }}</h1>
<div style="margin-top: 2rem; position: relative;">
	<button class="close" (click)="closePage()"></button>
	<div class="wrapper">
			<ng-container *ngIf="gameRulesContentDefined">
				<ng-container *ngIf="gameRulesHtml?.introduction !== null && gameRulesHtml?.introduction !== ''">
					<div [innerHTML]="gameRulesHtml?.introduction| hgReplacePlaceholders:placeholderValues | gwSafeHtml">
					</div>
				</ng-container>

				<div id="view-pay-tables-container">
					<span id="view-pay-tables" (click)="goToPayTables()">{{ 'game-rules.view-pay-table' | translate }}</span>
				</div>
	
				<ng-container *ngIf="gameRulesHtml?.sections.length > 0">
					<div *ngFor="let section of gameRulesHtml?.sections">
						<h3>{{ section.title }}</h3>
						<div *ngIf="section.content !== null"
							[innerHTML]="section.content| hgReplacePlaceholders:placeholderValues | gwSafeHtml"></div>
					</div>
				</ng-container>
	
				<p>
					<b [innerHTML]="'game-rules.accept-t-and-cs' | translate: { 'gameName': gameName } | gwSafeHtml"></b>
				</p>
			</ng-container>
	</div>

</div>