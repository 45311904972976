import { Component, OnInit, OnDestroy } from '@angular/core';
import { DisplayContentService, GameService } from '../shared';
import { SchedulerIntervalType } from '../shared/enums';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

@Component({
	selector: 'gw-jackpot',
	templateUrl: './jackpot.component.html',
	styleUrls: ['./jackpot.component.scss']
})
export class JackpotComponent implements OnInit, OnDestroy {
	currentJackpot: number;
	currencyCode: string;

	drawClosesInSec = 0;
	drawClosesInMin = 0;
	drawClosesInHrs = 0;
	drawClosesInDays = 0;

	salesEndDate: Date;
	private salesEndInterval: any;

	private isFirstInterval = true;
	private multipleSchedulers = false;
	private schedulerIndex = 0;
	private schedulerLength = 0;
	private drawSalesEndDatesDiff = 0;

	private drawDate: Date;

	constructor(private gameService: GameService, private displayContentService: DisplayContentService) { }

	ngOnInit(): void {
		this.currencyCode = this.gameService.gameGroup.currencyCode;
		this.currentJackpot = this.getJackpot();

		dayjs.extend(utc);
		dayjs.extend(weekOfYear);
		this.schedulerLength = this.gameService.gameGroup.gameRule.schedulers.length;
		this.multipleSchedulers = (this.schedulerLength > 1);

		if (this.multipleSchedulers) {
			this.setSchedulerIndex();
		}

		this.getNextSalesEndDate();
	}

	ngOnDestroy() {
		if (this.salesEndInterval !== undefined) {
			clearInterval(this.salesEndInterval);
		}
	}

	private getJackpot() {
		let jackpot = this.gameService.gameGroup.jackpotAmount;

		if(!!jackpot) {
			return jackpot;
		}

		const financialRule = this.gameService.gameGroup.gameFinancialRules[0];
		return financialRule?.prizeCategories[0]?.amounts?.find(amount => amount.currencyCode == this.currencyCode)?.prizeAmount;
	}

	private getNextSalesEndDate() {
		// if (this.gameService.gameGroup.salesEnd !== null) {
		this.drawDate = this.getSchedulerDrawDate();
		//console.log(this.drawDate);

			if (this.isFirstInterval) {
				const salesEnd = new Date();
				this.drawSalesEndDatesDiff = this.drawDate.getTime() - salesEnd.getTime();
			}

			// this.salesEndDate = new Date(this.drawDate.getTime() - this.drawSalesEndDatesDiff);
		this.salesEndDate = new Date(this.drawDate);

			this.startInterval();
		// }
	}

	private startInterval() {
		this.salesEndInterval = setInterval(() => {
			const now = new Date();
			const diff = this.salesEndDate.getTime() - now.getTime();

			if (diff >= 0) {
				this.drawClosesInSec = Math.floor((diff / 1000) % 60);
				this.drawClosesInMin = Math.floor((diff / (1000 * 60)) % 60);
				this.drawClosesInHrs = Math.floor((diff / (1000 * 60 * 60)) % 24);
				this.drawClosesInDays = Math.floor((diff / (1000 * 60 * 60 * 24)));
			} else {
				clearInterval(this.salesEndInterval);
				// this.countdownFinish.emit();
				
				this.isFirstInterval = false;
				
				this.getSchedulerIndex();
				this.getNextSalesEndDate();
				setTimeout(() => {
					this.displayContentService.animationEnd.next();
				}, 3000);
			}
		}, 1000);
	}

	private getSchedulerIndex() {
		if (this.multipleSchedulers) {
			const maxIndex = this.schedulerLength - 1;
			const tempSchedulerIndex = this.schedulerIndex + 1;

			this.schedulerIndex = (tempSchedulerIndex > maxIndex) ? 0 : tempSchedulerIndex;
		}
	}

	private setSchedulerIndex() {
		const now = new Date();
		let lastDiff = 0;
		let schedulerIndex = 0;

		this.gameService.gameGroup.gameRule.schedulers.forEach((scheduler, i) => {
			const utcDate = this.getSchedulerDrawDate(i);

			const diff = utcDate.getTime() - now.getTime();
			if (i !== 0) {
				if (diff < lastDiff) {
					lastDiff = diff;
					schedulerIndex = i;
				}
			} else {
				lastDiff = diff;
				schedulerIndex = i;
			}
		});

		this.schedulerIndex = schedulerIndex;
	}

	private getSchedulerDrawDate(index?: number): Date {
		const schedulerIndex = index || this.schedulerIndex;
		const scheduler = this.gameService.gameGroup.gameRule.schedulers[schedulerIndex];

		const dateToUse = (this.drawDate === undefined) ? new Date() : this.drawDate;
		let tempDrawDate = dayjs(dateToUse).utc();

		if (scheduler.intervalTypeID >= SchedulerIntervalType.Monthly) {
			const nowMonth = tempDrawDate.get('month');

			if (nowMonth < scheduler.month && this.isFirstInterval) {
				tempDrawDate = tempDrawDate.add((scheduler.month - nowMonth), 'month');
			} else {
				if (scheduler.intervalTypeID === SchedulerIntervalType.Yearly) {
					const nowYear = tempDrawDate.get('year');
					const yearsToAdd = this.addToInterval(nowYear, scheduler.intervalMultiplier);

					tempDrawDate = tempDrawDate.add(yearsToAdd, 'year');
				}

				tempDrawDate = tempDrawDate.set('month', scheduler.month);
			}
		}

		if (scheduler.intervalTypeID >= SchedulerIntervalType.Weekly) {
			const nowDay = tempDrawDate.get('day');

			if (nowDay < scheduler.dayOfWeek && this.isFirstInterval) {
				tempDrawDate = tempDrawDate.add((scheduler.dayOfWeek - nowDay), 'day');
			} else {
				if (scheduler.intervalTypeID === SchedulerIntervalType.Weekly) {
					const nowWeek = tempDrawDate.week();
					const weeksToAdd = this.addToInterval(nowWeek, scheduler.intervalMultiplier);

					tempDrawDate = tempDrawDate.add(weeksToAdd, 'week');
				}

				tempDrawDate = tempDrawDate.set('day', scheduler.dayOfWeek);
			}
		}

		if (scheduler.intervalTypeID >= SchedulerIntervalType.Daily) {
			const nowHour = tempDrawDate.get('hour');

			if (nowHour < scheduler.hour && this.isFirstInterval) {
				tempDrawDate = tempDrawDate.add((scheduler.hour - nowHour), 'hour');
			} else {
				if (scheduler.intervalTypeID === SchedulerIntervalType.Daily) {
					const nowDay = tempDrawDate.get('day');
					const daysToAdd = this.addToInterval(nowDay, scheduler.intervalMultiplier);

					tempDrawDate = tempDrawDate.add(daysToAdd, 'day');
				}

				tempDrawDate = tempDrawDate.set('hour', scheduler.hour);
			}
		}

		if (scheduler.intervalTypeID >= SchedulerIntervalType.Hourly) {
			const nowMinute = tempDrawDate.get('minute');

			if (nowMinute < scheduler.minute && this.isFirstInterval) {
				tempDrawDate = tempDrawDate.add((scheduler.minute - nowMinute), 'minute');
			} else {
				if (scheduler.intervalTypeID === SchedulerIntervalType.Hourly) {
					const nowHour = tempDrawDate.get('hour');
					const hoursToAdd = this.addToInterval(nowHour, scheduler.intervalMultiplier);

					tempDrawDate = tempDrawDate.add(hoursToAdd, 'hour');
				}

				tempDrawDate = tempDrawDate.set('minute', scheduler.minute);
			}
		}

		if (scheduler.intervalTypeID === SchedulerIntervalType.Minutely) {
			const nowMinute = tempDrawDate.get('minute');
			const minutesToAdd = this.addToInterval(nowMinute, scheduler.intervalMultiplier);

			tempDrawDate = tempDrawDate.add(minutesToAdd, 'minute');
		}

		tempDrawDate = tempDrawDate.set('second', 0);
		tempDrawDate = tempDrawDate.set('millisecond', 0);

		return tempDrawDate.toDate();
	}

	private addToInterval(currentAmount: number, intervalMultiplier: number): number {
		const modulus = currentAmount % intervalMultiplier;
		return (modulus > 0) ? intervalMultiplier - modulus : intervalMultiplier;
	}
}
