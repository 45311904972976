import { Component, OnInit, ViewChild, AfterViewChecked, AfterViewInit, ComponentFactoryResolver, HostBinding } from '@angular/core';
import { WindowResizeService, DisplayContentService, AppLoaderService, AppConfigService } from './shared/services';
import { environment } from '../environments/environment';
import { ComponentHostDirective } from './shared/directives';
import { SignalRService } from './shared/services/signal-r.service';
import { DrawResult } from './shared/models';
import { DrawAnimationComponent } from './draw-animation';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Title, Meta } from '@angular/platform-browser';
import { gameGroupNameMap, gameGroupDescription } from './shared/mapping';

@Component({
	selector: 'gw-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [
		trigger('loadAnimation', [
			state('false', style({ opacity: 1 })),
			state('true', style({ opacity: 0, visibility: 'hidden' })),
			transition('0 <=> 1', [
				animate('0.5s cubic-bezier(0.55, 0.09, 0.68, 0.53)')
			])
		])
	]
})
export class AppComponent implements OnInit, AfterViewInit, AfterViewChecked {
	@ViewChild('overlayHost', { read: ComponentHostDirective, static: true }) overlayHost: ComponentHostDirective;
	@ViewChild('animationHost', { read: ComponentHostDirective, static: false }) animationHost: ComponentHostDirective;
	gameGroupCode: string;
	header: any;
	sticky: any;
	animationHidden = true;
	isLoading = false;

	@HostBinding('class') loadedGame = "none";

	constructor(
		private windowResizeService: WindowResizeService,
		private displayContentService: DisplayContentService,
		private signalRService: SignalRService,
		private componentFactoryResolver: ComponentFactoryResolver,
		private appLoaderService: AppLoaderService,
		private appConfigService: AppConfigService,
		private titleService: Title,
		private metaService: Meta,
	) { }

	ngOnInit() {
		this.loadedGame = this.appConfigService.gameGroupCode;
		this.gameGroupCode = this.appConfigService.gameGroupCode;
		this.windowResizeService.initWindowResize();

		this.displayContentService.overlayHost = this.overlayHost;

		this.displayContentService.toggleAnimation.asObservable().subscribe(res => {
			this.animationHidden = res;
		});

		this.displayContentService.animationEnd.asObservable().subscribe(() => {
			this.displayContentService.toggleAnimation.next(true);

			this.displayContentService.deleteComponent(this.displayContentService.animationHost,
				this.displayContentService.drawAnimationComponentFactory);
		});

		this.signalRService.sendData();

		this.signalRService.onBroadcastResults.subscribe(res => {
			// this.loadAnimation(res);
		});

		this.appLoaderService.loadChange.subscribe(res => {
			this.isLoading = res;
		});

		const gameGroupName = gameGroupNameMap[this.appConfigService.gameGroupCode] || "Helio Games Showcase"; 
		this.titleService.setTitle(`${gameGroupName} - Lottery by Helio Gaming`);
		this.metaService.addTags([
			{name: 'description', content: gameGroupDescription[this.gameGroupCode]},
			{name: 'robots', content: 'index, follow'}
		  ]);
	}

	ngAfterViewInit() {
		this.displayContentService.animationHost = this.animationHost;
		this.displayContentService.drawAnimationComponentFactory = this.componentFactoryResolver.resolveComponentFactory(DrawAnimationComponent);
	}

	ngAfterViewChecked(): void {
		try {
			this.header = document.getElementById('header');
			this.sticky = this.header.offsetTop;
		} catch (e) { }
	}

	loadAnimation(res: any) {
		const drawDate = new Date(res.drawDate);
		const now = new Date();

		let timeDiff = 0;

		if (drawDate > now) {
			timeDiff = drawDate.getTime() - now.getTime();
		}

		let numbers;

		res.drawResults.forEach(temp => {
			if (temp.boardNum === 1) {
				numbers = temp.result.split(',');
			}
		});

		const result = new DrawResult(res.drawID, new Date(res.drawDate), numbers);

		setTimeout(() => {
			this.displayContentService.createDrawAnimationComponent(result);
			this.displayContentService.drawAnimationInProgress = true;
			window.scrollTo(0, 0);
		}, timeDiff);
	}
}
