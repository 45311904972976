export const environment = {
	production: true,
	baseServiceUrl: 'https://feed.helioengine-ts2.com/api/',
	gameServiceBaseURL: 'https://online-game-api.helioengine-ts2.com/',
	cdnBaseUrl: '/',
	language: 'en',
	supportedLanguages: [
		'en',
		'tr',
		'pt',
		'es-419'
	],
	soundEnabled: false
};
