import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { AppLoaderService, GameService, WindowResizeService, DisplayContentService, AppLangService, AppConfigService } from './services';
import { HttpClientModule } from '@angular/common/http';
import { CurrencyAmountPipe, ReplaceCurrencyCodePipe, WholeNumberCurrencyPipe, ReplacePlaceholdersPipe, SafeHtmlPipe } from './pipes';
import { DynamicContentService } from './services/dynamic-content.service';
import { SoundService } from './services/sound.service';
import { ComponentHostDirective } from './directives';
import { SignalRService } from './services/signal-r.service';
import { SortArrayPipe } from './pipes/sort-array.pipe';

@NgModule({
	declarations: [
		CurrencyAmountPipe,
		ReplaceCurrencyCodePipe,
		WholeNumberCurrencyPipe,
		ReplacePlaceholdersPipe,
		SafeHtmlPipe,
		SortArrayPipe,
		ComponentHostDirective
	],
	imports: [
		CommonModule
	],
	providers: [
		GameService,
		AppLoaderService,
		HttpClientModule,
		CurrencyPipe,
		CurrencyAmountPipe,
		ReplaceCurrencyCodePipe,
		WholeNumberCurrencyPipe,
		SortArrayPipe,
		DynamicContentService,
		ReplacePlaceholdersPipe,
		SafeHtmlPipe,
		SoundService,
		WindowResizeService,
		DisplayContentService,
		AppLangService,
		SignalRService,
		AppConfigService
	],
	exports: [
		CurrencyAmountPipe,
		ReplaceCurrencyCodePipe,
		SortArrayPipe,
		WholeNumberCurrencyPipe,
		ReplacePlaceholdersPipe,
		SafeHtmlPipe,
		ComponentHostDirective
	]
})
export class SharedModule { }
