import { Dictionary } from '../shared/models/dictionary';

export const GameRulesContentConfig = new Dictionary<string>();

GameRulesContentConfig.add('eurojackpot', 'euro-jackpot');
GameRulesContentConfig.add('euromillions', 'euro-millions');
GameRulesContentConfig.add('megamillions', 'mega-millions');
GameRulesContentConfig.add('mega-sena', 'mega-sena');
GameRulesContentConfig.add('powerball', 'power-ball');
GameRulesContentConfig.add('lotto-6aus49', 'lotto-6-49');
GameRulesContentConfig.add('heromillions', 'hero-millions');
GameRulesContentConfig.add('thedailyhero', 'the-daily-hero');
GameRulesContentConfig.add('thebighero', 'the-big-hero');
GameRulesContentConfig.add('lotto-6-36', 'lotto-6-36');
GameRulesContentConfig.add('lottorush', 'lotto-rush');
GameRulesContentConfig.add('lottohero', 'lotto-hero');
GameRulesContentConfig.add('lottohero-5minute', 'lotto-hero');
