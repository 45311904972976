<div class="game-container">
	<div class="header">
		<span>{{ 'draw-animation.header.draw' | translate }}</span>
		<span *ngIf="showSkipButton && !showSkipLoading" class="skip-animation" (click)="skipAnimation()">{{ 'draw-animation.header.skip-animation' | translate }}</span>
		<span *ngIf="showSkipLoading" class="skip-loading"></span>
		<span class="close-animation" (click)="closeAnimation()"></span>
	</div>
	<canvas id="canvas">
		alternate content
	</canvas>
	<div class="draw-info">
		<span class="draw-date">{{ drawDate | date:'short':undefined:localeID }}</span>
		<span class="draw-id">{{ 'draw-animation.draw-id' | translate: { 'drawID': drawID } }}</span>
	</div>
</div>
