<div id="jackpot" class="section">
	<gw-jackpot style="overflow: auto;"></gw-jackpot>
</div>
<div id="draws" class="section">
	<gw-draws [draws]="homeDraws"></gw-draws>
</div>
<div id="about" class="section">
	<gw-game-description></gw-game-description>
</div>
<div id="stats" class="section" *ngIf="pastNumberIndicators.length > 0">
	<gw-statistics [statistics]="pastNumberIndicators"></gw-statistics>
</div>
<!-- <div id="faqs" class="section">
	<gw-faqs></gw-faqs>
</div> -->

<!-- <button (click)="loadAnimation()">Load Animation</button> -->