import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { CountdownEmit } from './countdown-emit.model';

@Injectable()
export class CountdownService {

	private showCountdown: Subject<CountdownEmit>;
	private showAnimation: Subject<boolean>;
	private countdownDone: Subject<boolean>;

	constructor() {
		this.showCountdown = new Subject<CountdownEmit>();
		this.showAnimation = new Subject<boolean>();
		this.countdownDone = new Subject<boolean>();
	}

	get countDownDone(): Observable<boolean> {
		return this.countdownDone.asObservable();
	}

	get displayCountdown(): Observable<CountdownEmit> {
		return this.showCountdown.asObservable();
	}

	get displayAnimation(): Observable<boolean> {
		return this.showAnimation.asObservable();
	}

	toggleCountdown(showCountdown: CountdownEmit) {
		this.showCountdown.next(showCountdown);
	}

	toggleAnimation() {
		this.showAnimation.next(true);
	}

	hideAnimation() {
		this.showAnimation.next(false);
	}
}
