import { registerLocaleData } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppLangService, AppConfigService } from './shared/services';

let languageCodeToLoad: string;

export function appInitLoader(
	appLangService: AppLangService,
	translateService: TranslateService,
	appConfigService: AppConfigService
) {
	return async () => {
		await appConfigLoader(appConfigService);

		await loadLanguage(translateService, appLangService);

		await dynamicLanguageLoad(appLangService);

		return Promise.resolve();
	};
}

function appConfigLoader(appConfigService: AppConfigService) {
	return new Promise(resolve => {
		appConfigService.loadAppConfig()
			.then(() => resolve());
	});
}

function loadLanguage(translateService: TranslateService, appLangService: AppLangService) {
	return new Promise(resolve => {
		translateService.setDefaultLang('en');
		languageCodeToLoad = localStorage.getItem('appLang');

		if (environment.supportedLanguages === undefined) {
			console.warn(`App Config does not specify supportedLanguages. Game Language will default to English.`);
			languageCodeToLoad = 'en';
		} else if (languageCodeToLoad !== null && languageCodeToLoad !== undefined && languageCodeToLoad !== '') {
			languageCodeToLoad = getSupportedLanguageCode(languageCodeToLoad, environment.supportedLanguages);
		} else {
			languageCodeToLoad = 'en';
		}

		if (languageCodeToLoad !== 'en') {
			translateService.use(languageCodeToLoad)
				.subscribe(() => {
					// console.log('load language successful');
				}, error => {
					console.log('load language failed');
					console.log(error);
					// load default language
					translateService.use('en').subscribe(() => {
						languageCodeToLoad = 'en';
						resolve();
					});
				}, () => {
					appLangService.selectedLanguage = languageCodeToLoad;
					resolve();
				});
		} else {
			// load default language
			translateService.use('en').subscribe(() => {
				languageCodeToLoad = 'en';
				appLangService.selectedLanguage = languageCodeToLoad;
				resolve();
			});
		}
	});
}

function dynamicLanguageLoad(appLangService: AppLangService) {
	return new Promise(resolve => {
		let localeID = languageCodeToLoad;

		if (environment.supportedLanguages === undefined) {
			console.warn(`App Config does not specify supportedLanguages. Game Locale will default to en-GB.`);
		} else {
			localeID = getSupportedLanguageCode(localeID, environment.supportedLanguages);
		}

		if (localeID !== 'en') {
			import(
				/* webpackInclude: /\/|\\(en|tr|pt|es-419).*\.js$/, webpackChunkName: "locale-data/[request]" */
				`@angular/common/locales/${localeID}.js`)
				.then(importedModule => {
					registerLocaleData(importedModule.default, localeID);
					appLangService.selectedLocaleID = localeID;
				}).catch(() => {
					loadDefaultLocale(appLangService);
				}).then(() => {
					resolve();
				});
		} else {
			loadDefaultLocale(appLangService);
			resolve();
		}
	});
}

function loadDefaultLocale(appLangService: AppLangService) {
	import(`@angular/common/locales/en-GB.js`)
		.then(importedModule => {
			registerLocaleData(importedModule.default, 'en-GB');
			appLangService.selectedLocaleID = 'en-GB';
		});
}

function getSupportedLanguageCode(gameConfigLanguageCode: string, supportedLanguages: string[]): string {
	let isLanguageSupported = supportedLanguages.indexOf(gameConfigLanguageCode) !== -1;

	if (isLanguageSupported) {
		return gameConfigLanguageCode;
	}

	if (!isLanguageSupported && gameConfigLanguageCode.length > 2) {
		const tempLanguageCode = gameConfigLanguageCode.substring(0, 2);
		isLanguageSupported = supportedLanguages.indexOf(tempLanguageCode) !== -1;

		if (isLanguageSupported) {
			return tempLanguageCode;
		}
	}

	return 'en';
}
