import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfigService } from './app-config.service';

@Injectable()
export class SignalRService {
	onBroadcastResults: Observable<any>;
	onBroadcastGame: Observable<any>;

	private drawResults: Subject<any> = new Subject<any>();
	private game: Subject<any> = new Subject<any>();

	private connection: SignalR.Hub.Connection;
	private proxy: SignalR.Hub.Proxy;
	private proxyGame: SignalR.Hub.Proxy;

	constructor(private appConfigService: AppConfigService) {
		this.onBroadcastResults = this.drawResults.asObservable();
		this.onBroadcastGame = this.game.asObservable();

		this.connection = $.hubConnection(environment.gameServiceBaseURL);
		this.proxy = this.connection.createHubProxy('gamegroupdrawsresultshub');
		this.proxyGame = this.connection.createHubProxy('gamegrouphub');

		// subscribeForGameResultsAsync
		this.proxy.on('broadcastResults', (data) => {
			this.dataRecieved(data);
		});

		this.proxyGame.on('broadcastGame', (data) => {
			this.gameDataRecieved(data);
		});
	}

	sendData() {
		this.connection.start().done(() => {
			this.proxy.invoke('subscribeForGameGroupDrawsResultsAsync', this.appConfigService.gameGroupCode);
			this.proxyGame.invoke('subscribeForGameGroupAsync', this.appConfigService.gameGroupCode);
		});
	}

	private dataRecieved(data: object) {
		//console.log('Recieved data...');
		//console.log(data);
		this.drawResults.next(data);
	}

	private gameDataRecieved(data: object) {
		//console.log('Game Recieved data...');
		//console.log(data);

		this.game.next(data);
	}
}
