import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { Draw, DynamicContent } from '../shared/models';
import { DynamicContentService, GameService, AppLangService, AppConfigService } from '../shared/services';
import { environment } from '../../environments/environment';
import { gameGroupNameMap } from '../shared/mapping';
import { HttpErrorResponse } from '@angular/common/http';
import { GameRulesContentConfig } from './game-rules-content.config';
import { Utilities } from '../shared/service-utils';
import { CurrencyAmountPipe, ReplaceCurrencyCodePipe, WholeNumberCurrencyPipe } from '../shared';

@Component({
	selector: 'gw-game-rules',
	templateUrl: './game-rules.component.html',
	styleUrls: ['./game-rules.component.scss']
})
export class GameRulesComponent implements OnInit, AfterContentInit {
	gameRulesContentDefined = false;
	
	gameName: string;

	gameRulesHtml: DynamicContent;
	gameRule: any;
	financialRule: any;

	placeholderValues: any = {};
	currencyCode:string;
	CurrencyAmountPipe: any;
	localeID: string;	
	jackpotAmount: string;
	defaultTicketPrice: string;

	constructor(
		private router: Router,
		private gameService: GameService,
		private dynamicContentService: DynamicContentService,
		private appLangService: AppLangService,
		private appConfigService: AppConfigService,
		private wholeNumCurrencyPipe: WholeNumberCurrencyPipe,
		private replaceCurrencyCodePipe: ReplaceCurrencyCodePipe,
	) { }

	ngOnInit(): void {
		this.currencyCode = this.appConfigService.currency;
		this.localeID = this.appLangService.selectedLocaleID;
		this.gameRule = this.gameService.gameGroup.gameRule;
		this.financialRule = this.gameService.gameGroup.gameFinancialRules;
		this.getGameName();
		this.getJackpotAmount();
	}

	ngAfterContentInit() {
		this.getHtmlContent();
	}

	closePage() {
		// back to home
		this.router.navigate(['/']);
	}

	goToPayTables() {
		this.router.navigate(['/pay-table']);
	}

	getJackpotAmount() {
		const jackpotPrizeCategory = this.financialRule.find(x => x.gameTier === 'tier-1').prizeCategories.find(tempCategory => tempCategory.prizeCategoryID === 1);

		const jackpotAmountString = Utilities.getLinePrice(jackpotPrizeCategory.amounts, 'prizeAmount', this.currencyCode);

		const currencyString = this.wholeNumCurrencyPipe.transform(+jackpotAmountString, this.currencyCode, this.localeID);
		this.jackpotAmount = this.replaceCurrencyCodePipe.transform(currencyString, this.currencyCode);
		this.placeholderValues.jackpotAmount = this.jackpotAmount;
		//console.log(this.placeholderValues.jackpotAmount);

	}

	getTicketPrice() {
		const defaultTicketPrices = this.financialRule.ticketPrices.find(tempPrice => tempPrice.isDefault === true);
		const defaultTicketPriceString = Utilities.getLinePrice(defaultTicketPrices.amounts, 'amount', this.currencyCode);

		const currencyString = this.wholeNumCurrencyPipe.transform(+defaultTicketPriceString, this.currencyCode, this.localeID);
		this.defaultTicketPrice = this.replaceCurrencyCodePipe.transform(currencyString, this.currencyCode);
		this.placeholderValues.defaultTicketPrice = this.defaultTicketPrice;
	}

	private getGameName() {
		this.gameName = gameGroupNameMap[this.appConfigService.gameGroupCode];
	}

	private getHtmlContent() {
		this.getGameRulesData(this.appLangService.selectedLanguage)
			.subscribe((gameRules: DynamicContent) => {
				this.gameRulesHtml = gameRules;
				this.gameRulesContentDefined = true;
			}, (error: HttpErrorResponse) => {
				if (error.status === 404) {
					this.getGameRulesData('en').subscribe((gameRules: DynamicContent) => {
						this.gameRulesHtml = gameRules;
						this.gameRulesContentDefined = true;
					}, () => {
						this.gameRulesContentDefined = false;
					});
				}
			});
	}

	private getGameRulesData(languageCode: string) {
		const gameFolder = GameRulesContentConfig.item(this.appConfigService.gameGroupCode);
		const contentPath = `${environment.cdnBaseUrl}assets/game-rules-data/${gameFolder}/${languageCode}.json`;
		return this.dynamicContentService.getGameRulesContent(contentPath);
	}
}
