<div class="wrapper">
	<div class="row">
		<div class="company-details-holder">
			<div class="company-logo">
				<small style="text-transform: uppercase;">{{ 'footer.powered-by' | translate }}</small>
				<a href="https://heliogaming.com/" target="_blank">
					<div class="footer-logo">
					</div>
				</a>
			</div>
			<div class="contact-details">
				<div class="address">
					<div class="icon" style="background: url('../../assets/img/icons/home.svg') no-repeat left center;">
					</div>
					<div>{{ 'footer.address' | translate }}</div>
				</div>
				<div class="phone">
					<div class="icon"
						style="background: url('../../assets/img/icons/phone.svg') no-repeat left center;"></div>
					<div>{{ 'footer.phone' | translate }}</div>
				</div>
				<div class="email">
					<div class="icon" style="background: url('../../assets/img/icons/mail.svg') no-repeat left center;">
					</div>
					<div>{{ 'footer.contact' | translate }}</div>
				</div>
			</div>
		</div>
		<div class="sitemap">
			<div class="nav">
				<div>
					<a href="./#jackpot">{{ 'navigation.current-jackpot' | translate }}</a>
				</div>
				<div>
					<a href="./#draws">{{ 'navigation.draws.draws' | translate }}</a>
					<a class="child-link" [routerLink]="['./draws']">• {{ 'navigation.view-all' | translate }}</a>
				</div>
				<div>
					<a href="./#about">{{ 'navigation.about-game' | translate }}</a>
					<a class="child-link" [routerLink]="['./pay-table']">• {{ 'navigation.pay-table' | translate: { 'isSecondary': 0 } }}</a>
					<a class="child-link" [routerLink]="['./game-rules']">• {{ 'navigation.game-rules' | translate }}</a>
				</div>
				<div *ngIf="pastNumberIndicators.length > 0">
					<a routerLink="/statistics">{{ 'navigation.statistics.statistics' | translate }}</a>
					<!-- <a class="child-link" [routerLink]="['/statistics']">• {{ 'navigation.view-all' | translate }}</a> -->
				</div>
			</div>
		</div>
	</div>
	<div class="row bottom">
		<div>
			<a href="https://www.authorisation.mga.org.mt/verification.aspx?lang=EN&amp;company=620f7880-e2d0-47e1-bfff-cdc01f15b54b&amp;details=1" target="_blank">
				<img src="https://heliogaming.com/wp-content/uploads/2019/07/mga-logo.png" alt="mga-logo">
			</a>
		</div>
		<div>
			{{ 'footer.bottom-note' | translate }}
		</div>
		<!-- <div class="social">
			<div class="facebook">
			</div>
			<div class="linkedin">
			</div>
		</div> -->
	</div>
</div>