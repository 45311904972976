import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { CountdownService, CountdownEmit } from './draw-animation-countdown';

import { DrawAnimationWorld, ExtractorClass, ShowAllClass } from './animation-logic';
import { SoundService } from '../shared/services/sound.service';
import { Sound, SoundPath } from '../shared/enums';
import { environment } from '../../environments/environment';
import { WindowResizeService, DisplayContentService, AppLangService, AppConfigService } from '../shared/services';

@Component({
	selector: 'gw-draw-animation',
	styleUrls: [
		'./draw-animation.component.scss'
	],
	templateUrl: './draw-animation.component.html'
})

export class DrawAnimationComponent implements OnInit, OnDestroy {
	extractor: ExtractorClass;

	@Input() drawID = 1;
	@Input() drawDate: Date = new Date();
	@Input() numbers: number[] = [1, 2, 3, 4, 5, 6, 7];
	@Input() cards: number[] = [1];

	@Output() numberPick: EventEmitter<object> = new EventEmitter<object>();

	currencyCode: string;
	localeID: string;
	isMobile: boolean;

	isOptedIn: boolean;
	showSkipButton = false;
	showSkipLoading = false;

	secondaryTicketPrice: number;

	private stage;
	private assets = new Array();
	private showAll: ShowAllClass;
	private drawWorld: DrawAnimationWorld;

	private displayAnimationSubscription: Subscription;
	private animationDoneSubscription: Subscription;
	private boardItemPickedSubscription: Subscription;

	constructor(
		private drawAnimationCountdownService: CountdownService,
		private soundService: SoundService,
		private windowResizeService: WindowResizeService,
		private displayContentService: DisplayContentService,
		private appLangService: AppLangService,
		private appConfigService: AppConfigService
	) { }

	ngOnInit() {
		this.currencyCode = this.appConfigService.currency;
		this.localeID = this.appLangService.selectedLocaleID;

		this.windowResizeService.resizeObservable
			.subscribe(data => {
				if (data !== null) {
					this.isMobile = data.isMobile;
				}
			});

		this.displayContentService.createDrawAnimationCountdownComponent();

		this.drawAnimationCountdownService.toggleCountdown(new CountdownEmit(true));

		this.initDrawAnimation();

		this.displayContentService.windowVisibilityDrawAnimationEnd.asObservable().subscribe(() => {
			this.onEndExtractor();
		});
	}

	skipAnimation() {
		this.showSkipButton = false;
		this.showSkipLoading = true;
		this.extractor.ballContainer.stopAnimation();
	}

	closeAnimation() {
		this.displayContentService.toggleAnimation.next(true);

		this.displayContentService.deleteComponent(this.displayContentService.animationHost,
			this.displayContentService.drawAnimationComponentFactory);
	}

	initDrawAnimation() {
		this.drawWorld = new DrawAnimationWorld();
		this.drawWorld.setIsMobile(this.isMobile);
		this.drawWorld.setDrawNumbers();
		this.drawWorld.listExtract = this.numbers;
		this.stage = new createjs.Stage('canvas');

		const context = this.stage.canvas.getContext('2d');
		context.webkitImageSmoothingEnabled = context.mozImageSmoothingEnabled = true;
		createjs.Ticker.timingMode = createjs.Ticker.RAF;

		this.loadAssets();
		createjs.Ticker.addEventListener('tick', this.update.bind(this));

		if (environment.soundEnabled) {
			this.soundHandler();
		}

		this.drawWorld.setNewVersion(false);
		this.resize();
	}

	loadAssets() {

		const manifest = [
			{
				src: 'assets/img/animation/tray.png',
				id: 'tray'
			},
			{
				src: 'assets/img/animation/slotNumber.png',
				id: 'slotNumber'
			},
			{
				src: 'assets/img/animation/slotNumber_white.png',
				id: 'slotNumber_white'
			},
			{
				src: 'assets/img/animation/background-picked.png',
				id: 'background-picked'
			},
			{
				src: 'assets/img/animation/line.png',
				id: 'line'
			}

		];

		this.drawWorld.loader.on('fileload', this.handleFileLoad, this);
		this.drawWorld.loader.on('complete', this.handleComplete, this);
		this.drawWorld.loader.loadManifest(manifest);
		this.drawWorld.loader.load();

	}

	handleFileLoad(event) {
		this.assets.push(event.item);
	}

	startExtraction() {
		this.showAll.showCollapsed = 1;
		this.showAll.collapseFirst(true);
	}

	onEndShowAll() {
		this.drawWorld.control.setDrawStarted(true);
		this.showSkipButton = this.drawWorld.control.isDrawStarted;
		this.stage.removeChild(this.showAll);
		this.showAll = null;
		this.extractor.init();
		this.soundHandlers();
	}

	onEndExtractor() {
		this.stage.removeChild(this.extractor);
		this.extractor = null;
	}

	resize() {
		this.stage.canvas.style.width = '100%';
		if (this.isMobile) {
			this.stage.canvas.style.height = 'calc(100% - 162px)';
		} else {
			this.stage.canvas.style.height = 'calc(100% - 67px)';
		}

		this.stage.canvas.width = this.stage.canvas.offsetWidth;
		this.stage.canvas.height = this.stage.canvas.offsetHeight;

		this.stage.canvas.style.borderBottomLeftRadius = '36px';
		this.stage.canvas.style.borderBottomRightRadius = '36px';

		this.drawWorld.setC_width(this.stage.canvas.width);
		this.drawWorld.setC_height(this.stage.canvas.height);

		const maschera = new createjs.Shape();
		this.stage.mask = (maschera);

		const currentScaleX = 1;
		const currentScaleY = 1;

		this.drawWorld.setCurrentScale(Math.min(currentScaleX, currentScaleY));

		if (this.showAll && this.showAll.active) {
			this.showAll.resize();
		}
		if (this.extractor && this.extractor.active) {
			this.extractor.resize();
		}
		this.stage.update();
	}

	update(event) {
		this.stage.update();
	}

	handleComplete(event) {
		this.showAll = new ShowAllClass();

		this.showAll.addEventListener('end', this.onEndShowAll.bind(this));
		// this.stage.addChild(this.showAll);

		this.extractor = new ExtractorClass(this.drawWorld);
		this.extractor.addEventListener('end', this.onEndExtractor);
		this.animationDoneSubscription = this.extractor.onAnimationDone.subscribe(() => {
			this.displayContentService.animationEnd.next();
		});

		this.setBallCardPickedHandler();
		this.stage.addChild(this.extractor);

		this.showAll.init(this.drawWorld);

		if (environment.soundEnabled) {
			this.showAll.onPlaySound.subscribe(() => {
				this.soundService.playSound(Sound.BALLS_FALLING);
			});
		}

		this.displayAnimationSubscription = this.drawAnimationCountdownService.displayAnimation.subscribe(
			res => {
				// this.startExtraction();
				this.onEndShowAll();
			}
		);
	}

	ngOnDestroy() {
		if (environment.soundEnabled) {
			let sound = this.soundService.getSound(Sound.DRAW_BG_MUSIC);
			if (sound !== undefined) {
				sound.fade(1, 0, 1500);
			}

			sound = this.soundService.getSound(Sound.MAIN_BG_MUSIC);
			if (sound !== undefined) {
				sound.fade(0, 1, 1500)
					.once('fade', () => {
						this.soundService.stopSound(Sound.DRAW_BG_MUSIC);
					});
			}

			sound = this.soundService.getSound(Sound.DRAW_SPIN);
			if (sound !== undefined) {
				sound.stop();
			}

			sound = this.soundService.getSound(Sound.BALLS_FALLING);
			if (sound !== undefined) {
				sound.stop();
			}
		}

		if (this.displayAnimationSubscription !== undefined) {
			this.displayAnimationSubscription.unsubscribe();
		}

		if (this.animationDoneSubscription !== undefined) {
			this.animationDoneSubscription.unsubscribe();
		}

		if (this.boardItemPickedSubscription !== undefined) {
			this.boardItemPickedSubscription.unsubscribe();
		}
	}

	setBallCardPickedHandler() {
		this.boardItemPickedSubscription = this.extractor.onBoardItemPicked.subscribe(pickedBoardItem => {
			this.showSkipLoading = false;
			if (pickedBoardItem.boardItemType === 'number') {
				this.numberPick.emit(pickedBoardItem);
			} else if (pickedBoardItem.boardItemType === 'card') {
				this.numberPick.emit(pickedBoardItem);
			}
		});
	}

	private soundHandlers() {
		if (environment.soundEnabled) {
			this.extractor.ballContainer.onPlaySound.subscribe(() => {
				this.soundService.playSound(Sound.DRAW_SPIN);
			});

			this.extractor.resumebar.onPlaySound.subscribe(() => {
				this.soundService.stopSound(Sound.DRAW_SPIN);
			});
		}
	}

	private soundHandler() {
		if (this.soundService.canLoadSounds) {
			this.loadSounds();
		} else {
			this.soundService.canLoadSoundsUpdate.subscribe(loadSounds => {
				if (loadSounds) {
					this.loadSounds();
				}
			});
		}
	}

	private loadSounds() {
		this.soundService.loadSound(Sound.DRAW_BG_MUSIC, SoundPath.DRAW_BG_MUSIC, true, 0);
		this.soundService.loadSound(Sound.DRAW_SPIN, SoundPath.DRAW_SPIN, false, 1);
		this.soundService.loadSound(Sound.BALLS_FALLING, SoundPath.BALLS_FALLING);

		this.soundService.getSound(Sound.MAIN_BG_MUSIC).fade(1, 0, 1500);
		if (!this.soundService.getSound(Sound.DRAW_BG_MUSIC).playing()) {
			this.soundService.playSound(Sound.DRAW_BG_MUSIC);
		}
		this.soundService.getSound(Sound.DRAW_BG_MUSIC).fade(0, 1, 1500);
	}
}
