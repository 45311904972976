import { Component, Input, OnInit, AfterViewInit, HostBinding, ComponentRef } from '@angular/core';

import { CountdownService } from './draw-animation-countdown.service';

import { CountdownEmit } from './countdown-emit.model';
import { drawAnimationCoutdownComponentAnimations } from './draw-animation-countdown.animations';
import { SafeUrl } from '@angular/platform-browser';
import { _TRANSLATE } from 'src/app/shared/service-utils/mark-for-translate';

@Component({
	selector: 'gw-draw-animation-countdown',
	styleUrls: [
		'./draw-animation-countdown.component.scss'
	],
	templateUrl: './draw-animation-countdown.component.html',
	animations: drawAnimationCoutdownComponentAnimations
})

export class DrawAnimationCountdownComponent implements OnInit, AfterViewInit {

	@Input() countdownComponentRef: ComponentRef<DrawAnimationCountdownComponent>;

	element: any = null;
	message = '';
	counter: any[] = [];
	hasBets: boolean;

	@HostBinding('@fade') fadeState = true;

	bgImagePath: SafeUrl = '';

	constructor(
		private drawAnimationCountdownService: CountdownService
	) {
	}

	ngOnInit() {
		this.counter.push(1, 2, 3, 4, 5);

		this.bgImagePath = '';

		if (this.hasBets) {
			this.message = _TRANSLATE('draw-animation.countdown.good-luck');
		} else {
			this.message = _TRANSLATE('draw-animation.countdown.enjoy');
		}
	}

	ngAfterViewInit() {
		this.mainDrawCountdown();
	}

	private mainDrawCountdown() {
		setTimeout(() => {
			$('#message_5').addClass('animationIn');
		}, 1000);
		setTimeout(() => {
			$('#message_5').addClass('animationOut');
			$('#message_4').addClass('animationIn');
		}, 2000);
		setTimeout(() => {
			$('#message_4').addClass('animationOut');
			$('#message_3').addClass('animationIn');
		}, 3000);
		setTimeout(() => {
			$('#message_3').addClass('animationOut');
			$('#message_2').addClass('animationIn');
		}, 4000);
		setTimeout(() => {
			$('#message_2').addClass('animationOut');
			$('#message_1').addClass('animationIn');
		}, 5000);
		setTimeout(() => {
			$('#message_1').addClass('animationOut');
			$('#wishing').addClass('animationIn');
		}, 6000);
		setTimeout(() => {
			$('#wishing').addClass('animationOut');
			$('#countdown').fadeOut(750, () => {
				this.drawAnimationCountdownService.toggleCountdown(new CountdownEmit(false));
			});
		}, 7000);
		setTimeout(() => {
			this.drawAnimationCountdownService.toggleAnimation();
			this.countdownComponentRef.destroy();
		}, 8000);
	}
}
