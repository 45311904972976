export class SlotClass extends createjs.Container {
	private texture;
	private delayAnimationIn;
	private slot;
	private slotWhite;
	private imageWidth;
	private imageHeight;

	private world;
	constructor(texture, delayAnimationIn, world, interval, isMobile) {
		super();
		const num = (interval % 2 === 0);
		this.world = world;
		this.texture = texture;
		this.delayAnimationIn = delayAnimationIn;
		const result = world.loader.getResult(this.texture);

		this.slot = new createjs.Bitmap(result);
		this.slotWhite = new createjs.Bitmap(result);

		this.slot.regX = this.slot.getBounds().width / 2;
		this.slotWhite.regX = this.slotWhite.getBounds().width / 2;

		if (isMobile) {
			if (this.world.secondaryDraw) {
				if (!(interval % 2 === 0)) {
					this.slot.regY = this.slot.getBounds().height / 2 + 80;
					this.slotWhite.regY = this.slotWhite.getBounds().height / 2 + 80;
					this.slot.regX = this.slot.getBounds().width / 2;
					this.slotWhite.regX = this.slotWhite.getBounds().width / 2;
				} else {
					this.slot.regY = this.slot.getBounds().height / 2;
					this.slotWhite.regY = this.slotWhite.getBounds().height / 2;
					this.slot.regX = this.slot.getBounds().width / 2;
					this.slotWhite.regX = this.slotWhite.getBounds().width / 2;
				}
			} else {
				if (!(interval % 2 === 0)) {
					this.slot.regY = this.slot.getBounds().height / 2 + 80;
					this.slotWhite.regY = this.slotWhite.getBounds().height / 2 + 80;
				} else {
					this.slot.regY = this.slot.getBounds().height / 2;
					this.slotWhite.regY = this.slotWhite.getBounds().height / 2;
				}
			}

			if (this.texture === 'slotCard') {
				this.slot.regY = this.slot.getBounds().height / 2 + 45;
				this.slotWhite.regY = this.slotWhite.getBounds().height / 2 + 45;
				this.slot.regX = this.slot.getBounds().width / 2 - 20;
				this.slotWhite.regX = this.slotWhite.getBounds().width / 2 - 20;

				if (this.world.secondaryDraw) {
					this.slot.regX = this.slot.getBounds().width / 2;
					this.slotWhite.regX = this.slotWhite.getBounds().width / 2;
				}

			}

		} else {
			this.slot.regY = this.slot.getBounds().height / 2;
			this.slotWhite.regY = this.slotWhite.getBounds().height / 2;
		}

		this.addChild(this.slot);
		this.addChild(this.slotWhite);

		const result2 = world.loader.getResult(this.texture + '_white');

		// if (!num) {
		// this.slotWhite.regY = this.slotWhite.getBounds().height / 2 + 70;
		// } else {
		// this.slotWhite.regY = this.slotWhite.getBounds().height / 2;
		// }

		this.slotWhite.alpha = 0;

		this.imageWidth = this.slot.getBounds().width;
		this.imageHeight = this.slot.getBounds().height;

		this.slot.alpha = 0;
		createjs.Tween.get(this.slot)
			.wait(this.delayAnimationIn)
			.to({ alpha: 1 }, 400);

	}

	showWhiteSlot() {
		createjs.Tween.get(this.slot)
			.to({ alpha: 0 }, 500);
		createjs.Tween.get(this.slotWhite)
			.to({ alpha: 1 }, 500);
	}

	showBlueSlot() {
		createjs.Tween.get(this.slot)
			.to({ alpha: 1 }, 500);
		createjs.Tween.get(this.slotWhite)
			.to({ alpha: 0 }, 500);
	}

	getWidth() {
		return (this.imageWidth * this.scaleX);
	}

	getHeight() {
		return (this.imageHeight * this.scaleY);
	}

	resize() {
		if (this.texture === 'slotCard') {
			this.scaleX = this.scaleY = Math.max(this.world.currentScale, 0.6);
		} else {
			this.scaleX = this.scaleY = this.world.currentScale;
		}
	}
}
