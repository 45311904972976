import { Observable, Subject } from 'rxjs';

import { SlotClass } from './slot.class';
import { SlotInterface } from './slot.interface';
import { BallInterface } from './ball.interface';
import { DrawAnimationWorld } from './draw-animation.world';
import { environment } from '../../../environments/environment';

export class Resumebar extends createjs.Container {
	public indexExtract: any;
	public cWidth;
	public cHeight;
	public onResumebarAnimationDone: Observable<void>;
	public onPlaySound: Observable<void>;

	private isResumebarAnimationDone: Subject<void> = new Subject<void>();
	private currBall;
	// private curr_card_extracted = 0;
	private slots: any[] = [];
	private slot: SlotClass;
	private balls: any[] = [];
	private backgroundPicked;
	private loader: any;
	// private curr_card: any;
	private currentScale: any = 1;
	private trayPosX: any;
	private trayPosY: any;
	private world: DrawAnimationWorld;
	private run = 0;
	private playSound: Subject<void> = new Subject<void>();

	constructor(world: any) {
		super();
		this.world = world;
		this.loader = world.loader;
		this.cWidth = world.c_width;
		this.cHeight = world.c_height;

		if (this.world.isMobile) {

			for (let i = 1; i <= this.world.drawNumbers; i++ ) {
				const slot = new SlotClass('slotNumber', i * 150, world, i, true);
				this.addChild(slot);
				this.slots.push(slot);
			}

			// let card = new SlotClass('slotCard', 9 * 150, world, 0, true);
			// this.addChild(card);
			// this.slots.push(card);
		} else {

			for (let i = 1; i <= this.world.drawNumbers; i++ ) {
				const slot = new SlotClass('slotNumber', i * 150, world, i, false);
				this.addChild(slot);
				this.slots.push(slot);
			}

			// let card = new SlotClass('slotCard', 9 * 150, world, 0, false);
			// this.addChild(card);
			// this.slots.push(card);
		}

		// let result = world.loader.getResult('background-picked');
		// this.background_picked = new createjs.Bitmap(result);
		// this.addChild(this.background_picked);
		// this.background_picked.alpha = 0;
		// this.background_picked.regX = this.background_picked.getBounds().width / 2;
		// this.background_picked.regY = this.background_picked.getBounds().height * 0.75;

		this.onResumebarAnimationDone = this.isResumebarAnimationDone.asObservable();
		this.onPlaySound = this.playSound.asObservable();
	}

	showWhiteSlot() {
		$(this.slots).each((index, item) => {
			const slotItem = item as SlotInterface;
			slotItem.showWhiteSlot();
		});
		this.addChild(this.backgroundPicked);
		createjs.Tween.get(this.backgroundPicked).to({   alpha: 1 }, 500);
	}

	showBlueSlot() {
		$(this.slots).each((index, item) => {
			const slotItem = item as SlotInterface;
			slotItem.showBlueSlot();
		});
		this.addChild(this.backgroundPicked);
		createjs.Tween.get(this.backgroundPicked).to({ alpha: 0 }, 500);
	}

	// onCardExtracted(card) {
	// 	this.curr_card = card;
	// 	this.addChild(this.background_picked);
	// 	this.addChild(this.curr_card);
	// 	this.animationCard();
	// }

	// animationCard() {
	// 	createjs.Tween.removeTweens(this.curr_card);
	// 	this.slots[this.world.drawNumbers].visible = true;
	// 	this.curr_card.scaleX = this.curr_card.scaleY = this.currentScale;
	// 	this.curr_card.x = this.c_width / 2;
	// 	this.curr_card.y = this.c_height / 2;

	// 	let cardScale = this.getCardScale();
	// 	let x = this.getPosX(this.world.drawNumbers);
	// 	let y = this.getPosY(this.world.drawNumbers);
	// 	if (this.world.isMobile) {
	// 		/*if (this.world.secondaryDraw) {
	// 			x = this.getPosX(this.world.drawNumbers);
	// 			y = this.getPosY(this.world.drawNumbers) - 50;
	// 		} else {*/
	// 			x = this.getPosX(this.world.drawNumbers) + 20;
	// 			y = this.getPosY(this.world.drawNumbers) - 50;
	// 		/*}*/

	// 	}

	// 	if (this.world.control.isDrawSkipped) {
	// 		this.curr_card.scaleX = this.curr_card.scaleY = this.currentScale;
	// 		this.curr_card.x = x;
	// 		this.curr_card.y = y;
	// 		this.curr_card.scaleX = cardScale;
	// 		this.curr_card.scaleY = cardScale;
	// 		createjs.Tween.get(this.curr_card)
	// 		.to({   x: x,
	// 				y: y, scaleX: cardScale, scaleY: cardScale
	// 			}, 0, createjs.Ease.getPowIn(2.2))
	// 		.to({   scaleX: cardScale * 1.2, scaleY: cardScale * 1.2 }, 0, createjs.Ease.getPowOut(2.2))
	// 			.call(this.hideSlotCard, null, this)
	// 		.to({   scaleX: cardScale, scaleY: cardScale }, 300, createjs.Ease.getPowIn(2.2))
	// 		.call(  this.onEndCurrCardAnimation, null, this);
	// 	} else {
	// 		createjs.Tween.get(this.curr_card)
	// 		.to({   x: x,
	// 			y: y, scaleX: cardScale, scaleY: cardScale }, 500, createjs.Ease.getPowIn(2.2))
	// 		.to({   scaleX: cardScale * 1.2, scaleY: cardScale * 1.2 }, 200, createjs.Ease.getPowOut(2.2))
	// 		.call(  this.hideSlotCard, null, this)
	// 		.to({   scaleX: cardScale, scaleY: cardScale }, 300, createjs.Ease.getPowIn(2.2))
	// 		.call(  this.onEndCurrCardAnimation, null, this);
	// 	}

	// }

	// getCardScale() {
	// 	return 0.85 * this.slots[this.world.drawNumbers].getWidth() / this.curr_card.getBounds().width;
	// }

	animationSelectExtracted() {
		this.run = this.run + 1;
		if (environment.soundEnabled) {
			this.playSound.next();
		}
		if (this.currBall) {
			this.slots[this.world.indexExtract].visible = true;

			createjs.Tween.removeTweens(this.currBall);
			this.currBall.resize();
			this.currBall.x = this.trayPosX;
			this.currBall.y = this.trayPosY;
			this.currBall.scaleX = this.currBall.scaleY = 1;
			let y;
			const x = this.getPosX(this.world.indexExtract);

			if (this.world.isMobile) {
				/*if (this.world.secondaryDraw) {
					if ((this.run % 2 === 0)) {
						y = this.getPosY(this.world.indexExtract) - 80;
					} else {
						y = this.getPosY(this.world.indexExtract);
						x = this.getPosX(this.world.indexExtract);
					}
				} else {*/
					if ((this.run % 2 === 0)) {
						y = this.getPosY(this.world.indexExtract) - 80;
					} else {
						y = this.getPosY(this.world.indexExtract);
					}
				/*}*/

			} else {
				y = this.getPosY(this.world.indexExtract);
			}

			if (this.world.control.isDrawSkipped) {
				this.drawAnimationSkipTween(40, x, y);
			} else {
				createjs.Tween.get(this.currBall).wait(250)
				.to({ 'rotation': 360, 'x': x, 'y': y, 'scaleX': 1, 'scaleY': 1 },
					500, createjs.Ease.getPowIn(2.2))
				.call(this.showEffect, null, this)
				.to({ scaleX: 1.2, scaleY: 1.2 }, 200, createjs.Ease.getPowOut(2.2))
				.call(this.hideSlot, null, this)
				.to({ scaleX: 1, scaleY: 1 }, 300, createjs.Ease.getPowIn(2.2))
				.call(this.onEndCurrBallAnimation, null, this);
			}

		}
	}

	drawAnimationSkipTween(timeToWait: number, x: number, y: number) {
		this.currBall.resize();
		this.currBall.x = x;
		this.currBall.y = y;
		createjs.Tween.get(this.currBall).wait(timeToWait)
		.call(this.showEffect, null, this)
		.to({ scaleX: 1.2, scaleY: 1.2 }, 200, createjs.Ease.getPowOut(2.2))
		.call(this.hideSlot, null, this)
		.to({ scaleX: 1, scaleY: 1 }, 300, createjs.Ease.getPowIn(2.2))
		.call(this.onEndCurrBallAnimation, null, this);
	}

	showEffect() {
		if (this.world.control.isDrawSkipped) {
			this.currBall.alpha = 1;
		}
	}

	hideSlot() {
		this.slots[this.world.indexExtract].visible = false;
	}

	// hideSlotCard() {
	// 	if (this.world.control.isDrawSkipped) {
	// 		this.curr_card.alpha = 1;
	// 	}
	// 	this.slots[this.world.drawNumbers].visible = false;
	// }

	// onEndCurrCardAnimation() {
	// 	this.curr_card_extracted = 1;
	// 	this.isResumebarAnimationDone.next();
	// }

	onEndCurrBallAnimation() {
		// this.world.setDrawProcessing(false);
		this.balls.push(this.currBall);
		this.currBall = null;
		const evt = new CustomEvent('nextExtraction');
		this.dispatchEvent(evt);

		if (this.balls.length === this.world.drawNumbers) {
			this.isResumebarAnimationDone.next();
		}
	}

	getPosX(index) {
		if (this.cWidth >= 750) {
			/*if (!this.world.secondaryDraw) {
				if (this.world.isMobile) {
					return ((0.5 + index) * this.c_width / 9) + 45;
				} else {
					return ((0.5 + index) * this.c_width / 9) + 70;
				}
			} else {*/
				return ((0.5 + index) * this.cWidth / this.world.drawNumbers);
			/*}*/
		} else {
			if (index <= this.world.drawNumbers - 1) {
				const offset = 10 + this.slots[0].getWidth() / 2;
				return (offset + index * (this.cWidth - this.slots[this.world.drawNumbers].getWidth() - 15 - offset) / this.world.drawNumbers);
			} else {
				return (this.cWidth - this.slots[this.world.drawNumbers].getWidth() / 2 - 10);
			}
		}
	}

	getPosY(index) {
		if (this.cWidth >= 750) {
			return (this.cHeight * 0.15);
		} else {
			if (index <= this.world.drawNumbers - 1) {
				let offset = -this.slots[this.world.drawNumbers].getHeight() / 2 + this.slots[0].getWidth() / 2;

				if (index % 2) {
					offset = -offset;
				}
				return (this.cHeight * 0.15 + offset);
			} else {
				return (this.cHeight * 0.15);
			}

		}
	}

	getHeight() {
		return (this.getPosY(this.world.drawNumbers) + this.slots[this.world.drawNumbers - 1].getHeight() / 2);
	}

	// ballGlowEffect() {
	// 	this.glowEffect();
	// }

	setTrayPos(trayPosX, trayPosY) {
		this.trayPosX = trayPosX;
		this.trayPosY = trayPosY;
	}

	resize() {
		this.animationSelectExtracted();
		// let backgroundScaleX = this.c_width / this.background_picked.getBounds().width;
		// let backgroundScaleY = this.c_height / this.background_picked.getBounds().height;
		// let backgroundScale = Math.max(backgroundScaleX, backgroundScaleY);
		// this.background_picked.scaleX = this.background_picked.scaleY = backgroundScale * 1.3;
		// this.background_picked.x = this.trayPosX;
		// this.background_picked.y = this.trayPosY;

		$(this.balls).each((index, item) => {
			const ballItem = item as BallInterface;
			ballItem.resize();
			ballItem.x = this.getPosX(index);
			ballItem.y = this.getPosY(index);
		});

		$(this.slots).each((index, item) => {
			const ballItem = item as BallInterface;
			ballItem.resize();
		});

		$(this.slots).each((index, item) => {
			const ballItem = item as BallInterface;
			ballItem.x = this.getPosX(index);
			ballItem.y = this.getPosY(index);
		});

		// if (this.curr_card) {
		// 	if (!this.curr_card_extracted) {
		// 		this.animationCard();
		// 	} else {
		// 		this.curr_card.x = this.getPosX(this.world.drawNumbers);
		// 		this.curr_card.y = this.getPosY(this.world.drawNumbers);
		// 		this.curr_card.scaleX = this.curr_card.scaleY = this.getCardScale();
		// 	}
		// }
	}

	onNumberExtracted(ball) {
		this.currBall = ball;
		// this.addChild(this.background_picked);
		this.addChild(ball);
		this.animationSelectExtracted();
	}
}
