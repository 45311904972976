import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { JackpotComponent } from './jackpot/jackpot.component';
import { DrawsComponent } from './draws/draws.component';
import { GameDescriptionComponent } from './game-description/game-description.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { FaqsComponent } from './faqs/faqs.component';
import { SharedModule } from './shared/shared.module';
import { PayTableComponent } from './pay-table/pay-table.component';
import { GameRulesComponent } from './game-rules/game-rules.component';
import { DrawsPageComponent } from './draws-page/draws-page.component';
import { StatisticsPageComponent } from './statistics-page/statistics-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { HomeResolver } from './home.resolver';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { CountdownService, DrawAnimationComponent, DrawAnimationCountdownComponent } from './draw-animation';

import { TranslateLoader, TranslateModule, TranslateCompiler, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { HelioDropdownModule } from './shared/components/dropdown/helio-dropdown.module';
import { AppLangSelectComponent } from './header/app-lang-select';
import { appInitLoader } from './app-init.loader';
import { AppLangService, AppConfigService } from './shared/services';

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		HomeComponent,
		JackpotComponent,
		DrawsComponent,
		GameDescriptionComponent,
		StatisticsComponent,
		FaqsComponent,
		PayTableComponent,
		GameRulesComponent,
		DrawsPageComponent,
		StatisticsPageComponent,
		ErrorPageComponent,
		DrawAnimationComponent,
		DrawAnimationCountdownComponent,
		AppLangSelectComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MatExpansionModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			},
			compiler: {
				provide: TranslateCompiler,
				useClass: TranslateMessageFormatCompiler
			}
		}),
		SharedModule,
		HelioDropdownModule
	],
	bootstrap: [AppComponent],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: appInitLoader,
			deps: [AppLangService, TranslateService, AppConfigService],
			multi: true
		},
		HomeResolver,
		CountdownService,
		Title
	],
	entryComponents: [
		DrawAnimationComponent,
		DrawAnimationCountdownComponent
	]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
