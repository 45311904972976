<div class="main">
	<div class="draw-time-msg" style="margin-top: 10px;">
		{{ 'draws.draw-time-msg' | translate }}
	</div>
	<ng-container *ngIf="!isSecondary">
		<ng-container *ngFor="let draw of currentDraws | slice:0:drawLimit">
			<div *ngIf="draw.results.indexOf(',') > 0" class="draw-holder" [class.draw-flex]="draw.boards.length === 1">
				<span *ngFor="let board of draw.boards; let i = index">
					<span *ngIf="i > 0" class="plus">+</span>
					<span class="board-numbers">
						<ng-container *ngIf="(i > 0 && !isCardGame) || i == 0">
							<span *ngFor="let number of board" class="number">
								{{ number }}
							</span>
						</ng-container>
						<ng-container *ngIf="(i > 0 && isCardGame)">
							<span *ngFor="let number of board" [ngSwitch]="number" class="card card-margin">
								<img *ngSwitchCase="1" src="../../assets/img/animation/hearts.png" />
								<img *ngSwitchCase="2" src="../../assets/img/animation/clubs.png" />
								<img *ngSwitchCase="3" src="../../assets/img/animation/spades.png" />
								<img *ngSwitchCase="4" src="../../assets/img/animation/diamonds.png" />
							</span>
						</ng-container>
					</span>
				</span>
				<div style="margin-top: 10px;text-align: center;">
					{{ 'draws.draw' | translate }}: #{{ draw.drawNumber }} - {{ draw.drawDate | date : 'd MMMM, y, HH:mm' }}
				</div>
			</div>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="isSecondary">
		<ng-container *ngFor="let draw of mergedDraws | slice:0:drawLimit">
			<div *ngIf="draw.firstDraw.results.indexOf(',') > 0" class="draw-holder" [class.draw-flex]="draw.firstDraw.boards.length === 1">
				<div class="secondary-draw-wrap">
					<span *ngFor="let board of draw.firstDraw.boards; let i = index">
						<span *ngIf="i > 0" class="plus">+</span>
						<span class="board-numbers">
							<ng-container *ngIf="(i > 0 && !isCardGame) || i == 0">
								<span *ngFor="let number of board" class="number">
									{{ number }}
								</span>
							</ng-container>
							<ng-container *ngIf="(i > 0 && isCardGame)">
								<span *ngFor="let number of board" [ngSwitch]="number" class="card card-margin">
									<img *ngSwitchCase="1" src="../../assets/img/animation/hearts.png" />
									<img *ngSwitchCase="2" src="../../assets/img/animation/clubs.png" />
									<img *ngSwitchCase="3" src="../../assets/img/animation/spades.png" />
									<img *ngSwitchCase="4" src="../../assets/img/animation/diamonds.png" />
								</span>
							</ng-container>
						</span>
					</span>	
					<div class="secondary-draw-num">Primary</div>
				</div>
				<div class="secondary-draw-wrap">
					<span *ngFor="let board of draw.secondDraw.boards; let i = index">
						<span *ngIf="i > 0" class="plus">+</span>
						<span class="board-numbers">
							<ng-container *ngIf="(i > 0 && !isCardGame) || i == 0">
								<span *ngFor="let number of board" class="number">
									{{ number }}
								</span>
							</ng-container>
							<ng-container *ngIf="(i > 0 && isCardGame)">
								<span *ngFor="let number of board" [ngSwitch]="number" class="card card-margin">
									<img *ngSwitchCase="1" src="../../assets/img/animation/hearts.png" />
									<img *ngSwitchCase="2" src="../../assets/img/animation/clubs.png" />
									<img *ngSwitchCase="3" src="../../assets/img/animation/spades.png" />
									<img *ngSwitchCase="4" src="../../assets/img/animation/diamonds.png" />
								</span>
							</ng-container>
						</span>
					</span>
				</div>
				<div class="secondary-draw-num">Secondary</div>
				<div style="margin-top: 10px;text-align: center;">
					{{ 'draws.draw' | translate }}: #{{ draw.drawNumber }} - {{ draw.drawDate | date : 'd MMMM, y, HH:mm' }}
				</div>
			</div>
		</ng-container>
	</ng-container>

	<div *ngIf="!viewLink" class="pagination">
		<span (click)="goToPage(currentPage - 1)">&laquo;</span>
		<ng-container *ngFor="let x of pagesArray;index as i">
			<span *ngIf="i === 0 && x === '...'" (click)="goToPage(currentPage - 10)">...</span>
			<span *ngIf="x !== '...'" (click)="goToPage(x)" [class.active]="x === currentPage">{{x + 1}}</span>
			<span *ngIf="i === pagesArray.length - 2 && x === '...'" (click)="goToPage(currentPage + 10)">...</span>
		</ng-container>
		<span (click)="goToPage(currentPage + 1)">&raquo;</span>
	</div>
</div>
<div *ngIf="viewLink" class="view-all-link">
	<span (click)="navigateToDraws()"> {{ 'navigation.draws.view-all-draws' | translate }}</span>
</div>