import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from 'ng4-click-outside';

import { DropdownComponent } from './dropdown.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
	imports: [
		CommonModule,
		ClickOutsideModule,
		PerfectScrollbarModule
	],
	declarations: [
		DropdownComponent
	],
	exports: [
		DropdownComponent
	]
})

export class HelioDropdownModule { }
