export class BallClass extends createjs.Container {
	private ballNumber: number;
	private container: createjs.Container;
	private text: createjs.Text;
	private currentScale = 1;

	constructor(ballNumber: number) {
		super();
		this.ballNumber = ballNumber;
		this.container = new createjs.Container();
		this.addChild(this.container);
		this.init();
	}

	init() {
		const shape = new createjs.Shape();
		shape.graphics.beginFill('#f9a935').drawCircle(0, 0, 50);
		this.container.addChild(shape);
		this.text = new createjs.Text(this.ballNumber.toString(), 'bold 50px Montserrat', '#fff');
		this.text.textBaseline = 'middle';
		this.container.addChild(this.text);
		this.positionText();
	}

	positionText() {
		const b = this.text.getBounds();
		this.text.x = - b.width / 2;
	}

	resize() {
		this.container.scaleX = this.container.scaleY = this.currentScale;
	}

	fadeIn(time: number, delay: number) {
		this.container.alpha = 0;
		createjs.Tween.get(this.container).wait(delay).to({ alpha: 1 }, time);
	}

	glowEffect() {
		const circle = new createjs.Shape();
		circle.graphics.beginFill('white').drawCircle(0, 0, 50);

		const glow = new createjs.Shape();
		glow.graphics.beginFill('white').drawRect(0, 0, 200, 30);
		glow.rotation = -45;
		glow.x = -300;
		glow.y = 55;
		this.container.addChild(glow);
		glow.mask = circle;

		createjs.Tween.get(glow).to({ x: 150 }, 1000, createjs.Ease.getPowInOut(4));
	}

}
