<div class="wrapper">
	<div id="logo" class="logo-holder {{ gameGroupCode }}" (click)="navigateHome()">
	</div>
	<div class="name-and-nav">
		<!-- <div class="game-name" (click)="navigateHome()">
			{{ gameGroupName }}
		</div> -->
		<div class="nav">
			<a routerLink="." fragment="jackpot">{{ 'navigation.current-jackpot' | translate }}</a>
			<a routerLink="." fragment="draws">{{ 'navigation.draws.draws' | translate }}</a>
			<a routerLink="." fragment="about">{{ 'navigation.about-game' | translate }}</a>
			<a *ngIf="pastNumberIndicators.length > 0" routerLink="/statistics">{{ 'navigation.statistics.statistics' | translate }}</a>
			<!-- <a routerLink="/statistics" fragment="faqs">{{ 'navigation.faqs' | translate }}</a> -->
			<!--<div style="width: 3rem; line-height: 30px;">
				<gw-language-select></gw-language-select>
			</div> -->
		</div>
	</div>
</div>