export enum Sound {
	TAB_CLICK,
	MAIN_BG_MUSIC,
	PICK_NUMBER,
	UNPICK_NUMBER,
	QUICKPICK,
	PLACE_BET,
	NORMAL_WIN_BG_MUSIC,
	BIG_WIN_BG_MUSIC,
	JACKPOT_WIN_BG_MUSIC,
	DRAW_BG_MUSIC,
	DRAW_SPIN,
	BALLS_FALLING,
	BET_CANCELLED,
	CROWD_CHEERING,
	FIREWORK_BLAST,
	FIREWORK_WHISTLE,
	JACKPOT_MONEY_FOUNTAIN,
	JACKPOT_MONEY_FOUNTAIN_CHIME,
	NUMBER_MATCHED,
	NUMBER_NOT_MATCHED
}
