import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GameService, Draw, Utilities, AppLangService, AppLoaderService } from '../shared';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

@Component({
	selector: 'gw-draws',
	templateUrl: './draws.component.html',
	styleUrls: ['./draws.component.scss']
})
export class DrawsComponent implements OnInit, OnChanges {
	@Input('draws') inputDraws: Draw[] = [];

	currentDraws: Draw[] = [];
	mergedDraws: any[] = [];
	viewLink = false;
	localeID: string;
	isCardGame = false;
	isSecondary = false;

	drawLimit: number;
	drawsPerPage = 20;
	currentPage = 0;
	pagesCount: number;
	pagesArray: any[];
	totalDrawCount: number;

	sliceStart: number;
	sliceEnd: number;

	constructor(
		private gameService: GameService,
		private router: Router,
		private appLoaderService: AppLoaderService,
		private appLangService: AppLangService
	) { }

	ngOnInit(): void {
		this.appLoaderService.isLoading = true;
		this.localeID = this.appLangService.selectedLocaleID;
		this.isCardGame = this.gameService.isCardGame;
		this.isSecondary = this.gameService.isSecondary;

		if (this.inputDraws.length > 0) {
			this.drawLimit = 2;
			this.loadHomeDraws();
		} else {
			this.drawLimit = this.drawsPerPage;
			this.goToPage(0);
		}

		this.totalDrawCount = this.gameService.totalDrawCount;
		this.pagesCount = Math.floor(this.totalDrawCount / this.drawsPerPage);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.inputDraws.previousValue !== changes.inputDraws.currentValue) {
			this.inputDraws = [...changes.inputDraws.currentValue];
			this.loadHomeDraws();
		}
	}

	loadHomeDraws() {
		this.currentDraws = [...this.inputDraws];
		this.viewLink = true;
		this.getBoards();
		this.totalDrawCount = this.gameService.totalDrawCount;
		this.appLoaderService.isLoading = false;
	}

	getBoards() {
		this.currentDraws.forEach(item => {
			item.boards = Utilities.formatDrawResults(item.results);
		});

		this.mergeSecondaryDraws();
	}

	navigateToDraws() {
		this.router.navigate(['/draws']);
	}

	async goToPage(page) {
		this.appLoaderService.isLoading = true;

		if (this.currentPage > this.pagesCount) {
			this.currentPage = this.pagesCount;
		} else if (page <= 0) {
			this.currentPage = 0;
		} else {
			this.currentPage = page;
		}
		
		const offset = this.currentPage * this.drawsPerPage;

		this.currentDraws = await this.gameService.getCachedDraws(offset, this.drawsPerPage);
			
		this.getBoards();
		this.editPagesArray();
		window.scrollTo(0, 0);

		this.appLoaderService.isLoading = false;
	}

	private editPagesArray() {
		if(this.pagesCount < 10) {
			this.pagesArray = [...Array(this.pagesCount).fill(0).map((x, i) => i), this.pagesCount];
		} else if(this.currentPage <= 6) {
			this.pagesArray = [...Array(10).fill(0).map((x, i) => i), "...", this.pagesCount];
		} else if (this.currentPage > 6 && this.currentPage < this.pagesCount - 6) {
			this.pagesArray = [
				'...', 
				...Array(4).fill(0).map((x, i) => i + this.currentPage - 4),
				...Array(5).fill(0).map((x, i) => i + this.currentPage),
				"...",
				this.pagesCount
			];
		} else if (this.currentPage >= this.pagesCount - 10) {
			this.pagesArray = [
				'...',
				...Array(10).fill(0).map((x, i) => i + this.pagesCount - 9),
			];
		}
	}

	private mergeSecondaryDraws() {
		if(!this.isSecondary) {
			return;
		}
		
		this.mergedDraws = this.currentDraws?.reduce((mergedDraws, currentDraw) => {
			const isSecondaryDraw = currentDraw.results.split(',').length === 8;
			let transformedDraw = {
				drawNumber: currentDraw.drawNumber,
				drawDate: currentDraw.drawDate,
			}

			if(mergedDraws.length === 0) {
				transformedDraw['firstDraw'] = {
					results: currentDraw.results,
					boards: currentDraw.boards
				};
				mergedDraws.push(transformedDraw);
				return mergedDraws;
			}

			let existingDraw = mergedDraws.find(d => d.drawNumber === transformedDraw.drawNumber) || null;

			if(existingDraw && isSecondaryDraw) {
				existingDraw['secondDraw'] = {
					results: currentDraw.results,
					boards: currentDraw.boards
				};
			} else if(!existingDraw && !isSecondaryDraw) {
				transformedDraw['firstDraw'] = {
					results: currentDraw.results,
					boards: currentDraw.boards
				};
				mergedDraws.push(transformedDraw);
				return mergedDraws;
			}
			
			return mergedDraws;
		}, []);
	}
}
