<div class="main">
	<div *ngFor="let stat of currentStatistics" class="stat-holder">
		<span *ngFor="let board of stat.boards; let i = index">
			<span *ngIf="i > 0" class="plus">+</span>
			<span class="board-numbers">
				<ng-container *ngIf="(i > 0 && !isCardGame) || i == 0">
					<span *ngFor="let number of board" class="number">
						{{ number }}
					</span>
				</ng-container>
				<ng-container *ngIf="(i > 0 && isCardGame)">
					<span *ngFor="let number of board" [ngSwitch]="number" class="card card-margin">
						<img *ngSwitchCase="1" src="../../assets/img/animation/hearts.png" />
						<img *ngSwitchCase="2" src="../../assets/img/animation/clubs.png" />
						<img *ngSwitchCase="3" src="../../assets/img/animation/spades.png" />
						<img *ngSwitchCase="4" src="../../assets/img/animation/diamonds.png" />
					</span>
				</ng-container>
			</span>
		</span>
		<div style="margin-top: 10px; text-transform: capitalize;" [ngSwitch]="stat.type">
			<span *ngSwitchCase="'Hottest'">{{ 'statistics.hottest-numbers' | translate }}</span>
			<span *ngSwitchCase="'Coldest'">{{ 'statistics.coldest-numbers' | translate }}</span>
		</div>
	</div>
	<div *ngIf="drawnNumberFrequencies !== undefined" class="frequency-title">
		<div class="title">
			{{ 'statistics.frequency-title' | translate }}
		</div>
		<div class="dropdown">
			<gw-dropdown *ngIf="years.length > 1" [labelProp]="'year'" [list]="years"
				[listSelection]="selectedYear" (listSelectionChange)="yearChange($event)">
			</gw-dropdown>
		</div>
	</div>
	<div *ngIf="drawnNumberFrequencies !== undefined && drawnNumberFrequencies.length === 0">
		{{ 'statistics.no-results' | translate }}
	</div>
	<div *ngFor="let board of drawnNumberFrequencies; let i = index" class="frequency-holder">
		<h3>{{ 'statistics.board' | translate }} {{ board.boardNum }}</h3>
		<div class="stat-frequency-header">
			<span *ngIf="i > 0 && isCardGame">{{ 'statistics.card' | translate }}</span>
			<span *ngIf="(i > 0 && !isCardGame) || i == 0">{{ 'statistics.number' | translate }}</span>
			<span>{{ 'statistics.frequency' | translate }}</span>
		</div>
		<div *ngFor="let number of board.drawnNumbersFrequency | hgSortArray:'frequency':'desc'" class="stat-frequency-row">
			<span *ngIf="i > 0 && isCardGame" [ngSwitch]="number.number" class="card">
				<img *ngSwitchCase="1" src="../../assets/img/animation/hearts.png" />
				<img *ngSwitchCase="2" src="../../assets/img/animation/clubs.png" />
				<img *ngSwitchCase="3" src="../../assets/img/animation/spades.png" />
				<img *ngSwitchCase="4" src="../../assets/img/animation/diamonds.png" />
			</span>
			<span *ngIf="(i > 0 && !isCardGame) || i == 0">{{ number.number }}</span>
			<span>{{ number.frequency }}</span>
		</div>
	</div>
</div>
<div *ngIf="viewLink" class="view-all-link">
	<span (click)="navigateToStatistics()">{{ 'navigation.statistics.view-statistics' | translate }}</span>
</div>