import { GameRule } from './game-rule.model';
import { GameFinancialRule } from './game-financial-rule.model';

export class GameGroup {
	code: string;
	name: string;
	salesEnd: Date;
	gameRule: GameRule;
	gameFinancialRules: GameFinancialRule[];
	jackpotAmount: number;
	currencyCode: string;
}
