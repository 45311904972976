import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import { DynamicContent } from '../models';
import { Dictionary } from '../models/dictionary';

/** Service used to get and cache dynamic HTML content for Game Rules and Game Description */
@Injectable()
export class DynamicContentService {
	/** Cached game rules HTML content */
	gameRulesContent: Dictionary<DynamicContent> = new Dictionary<DynamicContent>();

	/** Cached game Description HTML content */
	gameDescriptionContent: Dictionary<DynamicContent> = new Dictionary<DynamicContent>();

	constructor(
		private http: HttpClient
	) { }

	/**
	 * Get the contents of the Game Rules
	 * @param path Absolute path of the game rules content
	 * @returns Observable emitting and object containing the game rules content
	 */
	getGameRulesContent(path: string): Observable<DynamicContent> {
		if (!this.gameRulesContent.containsKey(path)) {
			return this.http.get<{ gameRules: DynamicContent }>(path)
				.pipe(
					map(data => {
						this.gameRulesContent.add(path, data.gameRules);
						return data.gameRules;
					})
				);
		}

		return of(this.gameRulesContent.item(path));
	}

	/**
	 * Get the contents of the Game Description
	 * @param path Absolute path of the game Description content
	 * @returns Observable emitting and object containing the game Description content
	 */
	getGameDescriptionContent(path: string): Observable<DynamicContent> {
		if (!this.gameDescriptionContent.containsKey(path)) {
			return this.http.get<{ gameDescription: DynamicContent }>(path)
				.pipe(
					map(data => {
						this.gameDescriptionContent.add(path, data.gameDescription);
						return data.gameDescription;
					})
				);
		}

		return of(this.gameDescriptionContent.item(path));
	}
}
