import { ComponentFactory, Injectable, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Subject } from 'rxjs';
import { DrawAnimationComponent } from '../../draw-animation/draw-animation.component';
import { ComponentHostDirective } from '../directives';
import { DrawAnimationCountdownComponent } from '../../draw-animation/draw-animation-countdown';

@Injectable()
export class DisplayContentService {
	toggleAnimation = new Subject<boolean>();
	animationInProgress = new Subject<boolean>();
	windowVisibilityDrawAnimationEnd = new Subject<void>();

	animationEnd = new Subject<void>();

	public drawAnimationInProgressValue = false;

	private mainDrawAnimationInProgressValue = false;
	private secondaryDrawAnimationInProgressValue = false;

	private overlayHostValue: ComponentHostDirective;
	private animationHostValue: ComponentHostDirective;

	private animationComponentFactoryValue: ComponentFactory<DrawAnimationComponent>;

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver
	) { }

	public get overlayHost(): ComponentHostDirective {
		return this.overlayHostValue;
	}

	public set overlayHost(host: ComponentHostDirective) {
		this.overlayHostValue = host;
	}

	public get animationHost(): ComponentHostDirective {
		return this.animationHostValue;
	}

	public set animationHost(host: ComponentHostDirective) {
		this.animationHostValue = host;
	}

	public get drawAnimationInProgress() {
		return this.drawAnimationInProgressValue;
	}

	public set drawAnimationInProgress(animationInProgress: boolean) {
		this.animationInProgress.next(animationInProgress);
		this.drawAnimationInProgressValue = animationInProgress;
	}

	public get mainDrawAnimationInProgress() {
		return this.mainDrawAnimationInProgressValue;
	}

	public set mainDrawAnimationInProgress(animationInProgress: boolean) {
		this.mainDrawAnimationInProgressValue = animationInProgress;
	}

	public get secondaryDrawAnimationInProgress() {
		return this.secondaryDrawAnimationInProgressValue;
	}

	public set secondaryDrawAnimationInProgress(animationInProgress: boolean) {
		this.secondaryDrawAnimationInProgressValue = animationInProgress;
	}

	public get drawAnimationComponentFactory(): ComponentFactory<DrawAnimationComponent> {
		return this.animationComponentFactoryValue;
	}

	public set drawAnimationComponentFactory(componentFactory: ComponentFactory<DrawAnimationComponent>) {
		this.animationComponentFactoryValue = componentFactory;
	}

	createComponent(componentHost: ComponentHostDirective, componentFactory: ComponentFactory<any>,
		callback?: () => void, deleteComponent: boolean = true) {

		const viewContainerRef: ViewContainerRef = componentHost.viewContainerRef;

		if (deleteComponent) {
			this.deleteComponent(componentHost, componentFactory);
		}

		const componentRef = viewContainerRef.createComponent(componentFactory);
	}

	createDrawAnimationCountdownComponent() {
		const viewContainerRef: ViewContainerRef = this.overlayHost.viewContainerRef;

		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DrawAnimationCountdownComponent);
		const componentRef = viewContainerRef.createComponent(componentFactory);

		const componentInstace = componentRef.instance;

		componentInstace.countdownComponentRef = componentRef;
	}

	createDrawAnimationComponent(data: any) {
		const viewContainerRef: ViewContainerRef = this.animationHost.viewContainerRef;
		this.deleteComponent(this.animationHost, this.drawAnimationComponentFactory);

		this.toggleAnimation.next(false);
		const componentRef = viewContainerRef.createComponent(this.drawAnimationComponentFactory);
		const componentInstance = componentRef.instance;

		componentInstance.drawID = data.drawID;
		componentInstance.drawDate = data.drawDate;
		componentInstance.numbers = data.numbers;
	}

	deleteComponent(componentHost: ComponentHostDirective, componentFactory?: ComponentFactory<any>, callback?: () => void) {
		const viewContainerRef: ViewContainerRef = componentHost.viewContainerRef;
		viewContainerRef.clear();

		if (callback) {
			callback();
		}
	}
}
