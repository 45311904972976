import { Injectable } from '@angular/core';
import { Howl, Howler } from 'howler';

import { Subject, Observable } from 'rxjs';

import { Sound, SoundPath } from '../enums';
import { Dictionary } from '../models/dictionary';
import { environment } from '../../../environments/environment';

@Injectable()
export class SoundService {
	sounds: Dictionary<Howl> = new Dictionary<Howl>();
	canLoadSoundsUpdate: Observable<boolean>;

	private canLoadSoundsValue = false;
	private canLoadSoundsSubject: Subject<boolean> = new Subject<boolean>();

	constructor() {
		this.canLoadSoundsUpdate = this.canLoadSoundsSubject.asObservable();
	}

	get canLoadSounds(): boolean {
		return this.canLoadSoundsValue;
	}

	set canLoadSounds(canLoadSounds: boolean) {
		this.canLoadSoundsValue = canLoadSounds;
		this.canLoadSoundsSubject.next(this.canLoadSoundsValue);
	}

	loadSound(key: Sound, path: SoundPath, loop: boolean = false, volume: number = 1): Howl {
		if (this.canLoadSounds) {
			if (!this.sounds.containsKey(key)) {
				const newSound = new Howl({
					src: `${environment.cdnBaseUrl}${path}`,
					loop,
					volume
				});

				this.sounds.add(key, newSound);
			}

			return this.getSound(key);
		}
	}

	getSound(key: Sound): Howl {
		if (this.canLoadSounds) {
			return this.sounds.item(key);
		}
	}

	muteSound(key: Sound, isMute: boolean = true): void {
		if (this.canLoadSounds) {
			this.getSound(key).mute(isMute);
		}
	}

	muteSounds(...keys: Sound[]) {
		if (this.canLoadSounds) {
			keys.forEach((k) => {
				this.muteSound(k);
			});
		}
	}

	pauseSound(key: Sound): void {
		if (this.canLoadSounds) {
			this.getSound(key).pause();
		}
	}

	playSound(key: Sound): void {
		if (this.canLoadSounds) {
			this.getSound(key).play();
		}
	}

	stopSound(key: Sound): void {
		if (this.canLoadSounds) {
			this.getSound(key).stop();
		}
	}

	muteAllSounds(key: Sound, isMute: boolean): void {
		if (this.canLoadSounds) {
			Howler.mute(isMute);
		}
	}
}
