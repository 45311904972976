import { Observable, Subject } from 'rxjs';

import { BallClass } from './ball.class';
import { BallInterface } from './ball.interface';
import { ControlClass } from './control.class';
import { MathUtilities } from '../math.utilities';
import { environment } from '../../../environments/environment';

export class BallContainer extends createjs.Container {
	public balls: any[] = [];
	public trayPosX;
	public trayPosY;
	public allowFire;
	public upperLimit;
	public container;
	public numBallEnd;
	public paramAnimation;
	public indexExtract;
	public listExtract: any[] = [3, 14, 7, 26, 12, 30, 29, 1, 7];
	public ball: BallClass;
	public world: any;
	public selectedBalls: any[] = [];
	public skipAnimation = false;
	public control: ControlClass;
	public onPlaySound: Observable<void>;

	private amountOfBalls = 50;
	private playSound: Subject<void> = new Subject<void>();

	constructor(world: any) {
		super();
		this.world = world;
		this.paramAnimation = world.paramAnimation;
		this.indexExtract = world.indexExtract;
		this.balls = new Array();
		this.container = new createjs.Container();
		this.addChild(this.container);
		this.trayPosX = 0;
		this.trayPosY = 0;
		this.allowFire = 0;
		this.upperLimit = 0;

		this.onPlaySound = this.playSound.asObservable();
	}

	public stopAnimation() {
		const controlData = this.world.control.stopDrawAnimation(this.allowFire, this.skipAnimation);
		this.allowFire = controlData.allowFire;
		this.skipAnimation = controlData.skipAnimation;
	}

	init() {
		const numbers = MathUtilities.unqiueRandomList(50, 1, 90, this.world.listExtract.slice()).sort((a, b) => a - b);

		for (const ballNumber of numbers) {
			const ball = new BallClass(ballNumber);
			this.container.addChild(ball);
			this.balls.push(ball);
		}
		createjs.Tween.get(this).wait(800).call(this.enableFire, null, this);
	}

	enableFire(next: number) {
		this.allowFire = 1;

		if (this.skipAnimation) {
			this.world.control.skipDrawAnimation();
		}

		this.fire(next);
	}

	fire(next: number) {
		this.world.setDrawProcessing(true);

		if (this.allowFire) {
			if (environment.soundEnabled) {
				this.playSound.next();
			}
			this.numBallEnd = 0;
			const currParam = this.world.paramAnimation[this.world.indexExtract];
			const currNumber = this.world.listExtract[this.world.indexExtract];
			let currBallTemp;

			$(this.balls).each((index, item) => {
				const ballItem = item as BallInterface;
				if (ballItem.ballNumber === currNumber) {
					currBallTemp = item;
					this.balls.splice(index, 1);
				}
			});

			this.balls.push(currBallTemp);

			$(this.balls).each((index, item) => {
					const ballItem =  item as BallInterface;
					ballItem.resize();
					createjs.Tween.removeTweens(item);
					ballItem.x = 0.5 * this.world.c_width;
					ballItem.y = this.world.c_height + 120;
					ballItem.rotation = 0;
					const destY = this.getRandom(currParam.destYMin, currParam.destYMax) * this.world.c_height;
					const delay = this.getRandom(currParam.delayMin, currParam.delayMax) * index;
					const time = this.getRandom(currParam.timeMin, currParam.timeMax);
					let destX;
					let finalY;
					let ratioX = 0.5;
					let offsetRotation = 0;
					let easeFinalY = createjs.Ease.linear;
					if (ballItem.ballNumber === currNumber) {
						destX = this.trayPosX;
						finalY = this.trayPosY;
						offsetRotation = 20 + Math.floor(Math.random() * 20);
						easeFinalY = createjs.Ease.bounceOut;

					} else {
						if (this.world.c_width > this.world.c_height) {
							ratioX = this.getRandom(currParam.destXMin, currParam.destXMax);
						} else {
							ratioX = this.getRandom(currParam.destXMin_alt, currParam.destXMax_alt);

						}
						destX = ratioX * this.world.c_width;
						finalY = this.world.c_height + 120;
					}

					let destX1 = 0;
					let destX2 = 0;
					let time1 = time;

					let time2 = 0;
					destX1 = destX2 = destX;
					if (destX > this.world.c_width - 50 * this.world.currentScale) {
						destX1 = this.world.c_width - 50 * this.world.currentScale;
						destX2 = this.world.c_width - destX + destX1;

						time1 = time * 0.5 / (ratioX - 0.5);
						time2 = time - time1;
					}
					if (destX < +50 * this.world.currentScale) {
						destX1 = +50 * this.world.currentScale;
						destX2 = -destX + destX1;
						time1 = time * 0.5 / (-ratioX + 0.5);
						time2 = time - time1;
					}

					const data = {
						destY,
						destX1,
						destX2,
						time,
						time1,
						time2,
						currBallTemp: item,
						delay,
						finalY,
						easeFinalY,
						offsetRotation
					};

					if (this.world.newVersion) {
						if (Number.isInteger(next)) {
							data.delay = 0;
							data.currBallTemp = currBallTemp;
						} else {
							data.currBallTemp = item;
						}
					} else {
						data.currBallTemp = item;
					}
					this.selectedBallTween(data, item);

				});

		} else {
			$(this.balls).each((index, item) => {
				const ballItem =  item as BallInterface;
				ballItem.y = this.world.c_height + 200;
			});

		}
	}

	selectedBallTween(data: any, item) {
		if (!this.world.control.isDrawSkipped) {
			createjs.Tween.get(item)
			.wait(data.delay)
			.to({ rotation: 360 + data.offsetRotation }, data.time)
			.call(this.onEndSingleBall, null, this);
			if (data.destY > this.upperLimit + 50 * this.world.currentScale) {
				data.destY = this.upperLimit + 50 * this.world.currentScale;
				createjs.Tween.get(data.currBallTemp)
					.wait(data.delay)
					.to({ y: data.destY }, data.time / 2, createjs.Ease.linear)
					.to({ y: data.finalY }, data.time / 2, data.easeFinalY);
			} else {
				// createjs.Tween.get(data.currBallTemp)
				// 	.wait(data.delay)
				// 	.to({ y: data.destY }, data.time / 2, createjs.Ease.getPowOut(2.2))
				// 	.to({ y: data.finalY }, data.time / 2, createjs.Ease.getPowIn(2.2));
			}

			createjs.Tween.get(data.currBallTemp)
			.wait(data.delay)
			.to({ x: data.destX1 }, data.time1, createjs.Ease.getPowIn(2.2))
			.to({ x: data.destX2 }, data.time2);

		} else {
			this.onEndSingleBall();
		}

	}

	onEndSingleBall() {
		this.numBallEnd++;
		if (this.numBallEnd === this.balls.length) {
			let currBall: BallInterface;
			const currNumber = this.world.listExtract[this.world.indexExtract];
			this.allowFire = 0;
			$(this.balls).each((index, item) => {
				const ballItem =  item as BallInterface;
				if (ballItem.ballNumber === currNumber) {
					currBall = item;
					this.balls.splice(index, 1);
				}
			});

			const	evt = new CustomEvent('numberExtracted');
			( evt as any).ball = currBall;
			this.dispatchEvent(evt);
		}

	}

	setTrayPos(trayPosX, trayPosY) {
		this.trayPosX = trayPosX;
		this.trayPosY = trayPosY;
	}

	getRandom(minValue, maxValue) {
		const rndOffset = maxValue - minValue;
		return (minValue + rndOffset * Math.random());
	}

	resize() {
		this.fire(0);
	}
}
