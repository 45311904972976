import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ResponseBodyType } from '../service-utils/response-body-type.enum';
import { AppConfigService } from './app-config.service';

export abstract class BaseService {
	/**
	 * @param http Used to pass the Angular Http client which is responsible to making HTTP requests
	 */
	constructor(
		protected http: HttpClient,
		protected appConfigService: AppConfigService
	) { }

	/**
	 * Function to make an HTTP GET request
	 * @param url The URL of the request to be called
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @param responseBodyType The type of response the request is expected to return
	 * @returns An Observable of the data returned from the server
	 */
	public get<T>(url: string, searchParams?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON): Observable<any> {

		const headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('APIKey', `${this.appConfigService.apiKey}`);

		const options: any = {
			headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		options.responseType = this.setResponseType(responseBodyType);

		return this.http.get<T>(url, options)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Function called to handle unexpected errors
	 */
	protected handleError(serviceErrors: HttpErrorResponse) {
		return _throw(serviceErrors);
	}

	/**
	 * Function to set responseType
	 */
	private setResponseType(responseBodyType: ResponseBodyType): string {
		switch (responseBodyType) {
			case ResponseBodyType.Text:
				return 'text';
			case ResponseBodyType.Blob:
				return 'blob';
			case ResponseBodyType.JSON:
			default:
				return 'json';
		}
	}
}
