import { Observable, Subject } from 'rxjs';
import { Resumebar } from './resumebar.class';
import { BallContainer } from './ballContainer.class';
import { TrayClass } from './tray.class';
import { CardContainerClass } from './cardContainer.class';
import { DrawAnimationWorld } from './draw-animation.world';

export class ExtractorClass extends createjs.Container {
	public indexExtract;
	public onAnimationDone: Observable<void>;
	public onBoardItemPicked: Observable<any>;
	public ballContainer: BallContainer;
	// public cardContainer: CardContainerClass;
	public resumebar: Resumebar;
	public active;

	private isAnimationDone: Subject<void> = new Subject<void>();
	private boardItemPicked: Subject<any> = new Subject<any>();
	private line;
	private tray: TrayClass;
	private paramAnimation: any;
	private loader: any;
	private cWidthValue: any;
	private cHeightValue: any;
	private skip: boolean;

	private world: DrawAnimationWorld;
	// private cardValue: number;
	private itemPickedIndex = 0;
	constructor(world: DrawAnimationWorld) {

		super();
		this.world = world;
		this.loader = world.loader;
		this.cHeightValue = world.c_height;
		this.cWidthValue = world.c_width;
		this.paramAnimation = world.paramAnimation;
		this.line = new createjs.Bitmap(world.loader.getResult('line'));
		this.addChild(this.line);

		this.line.alpha = 0;
		this.active = 0;
		this.onAnimationDone = this.isAnimationDone.asObservable();
		this.onBoardItemPicked = this.boardItemPicked.asObservable();
	}

	init() {

		this.active = 1;
		this.resumebar = new Resumebar(this.world);
		this.addChild(this.resumebar);
		this.resumebar.indexExtract = this.world.indexExtract;
		this.resumebar.addEventListener('nextExtraction', this.nextExtraction.bind(this));

		this.resumebar.onResumebarAnimationDone.subscribe(() => {
			this.isAnimationDone.next();
		});

		this.ballContainer = new BallContainer(this.world);
		this.addChild(this.ballContainer);
		this.ballContainer.addEventListener('numberExtracted', this.onNumberExtracted.bind(this));
		this.ballContainer.indexExtract = this.world.indexExtract;
		this.ballContainer.init();

		this.tray = new TrayClass(this.world);
		this.addChild(this.tray);
		this.tray.animationIn(600, 400);

		createjs.Tween.get(this.line).wait(200).to({ alpha: 1 }, 500);

		// this.cardContainer = new CardContainerClass(this.world);
		// this.addChild(this.cardContainer);
		// this.cardContainer.addEventListener('showBackground', this.showBackground.bind(this));
		// this.cardContainer.addEventListener('cardExtracted', this.onCardExtracted.bind(this));

		this.resize();

	}

	nextExtraction() {
		const next = this.world.indexExtract + 1;
		this.world.setIndexExtract(next);
		if (this.paramAnimation[this.world.indexExtract]) {
			this.resumebar.indexExtract = this.world.indexExtract;
			this.ballContainer.indexExtract = this.world.indexExtract;
			this.ballContainer.enableFire(next);

		}/* else {
			this.cardContainer.animationIn();
		}*/
	}

	showBackground() {
		// this.resumebar.showWhiteSlot();
		createjs.Tween.get(this).wait(1400).call(this.hideBackground, null, this);
	}

	hideBackground() {
		// this.resumebar.showBlueSlot();
	}

	// onCardExtracted(evt) {
	// 	this.resumebar.onCardExtracted(evt.card);
	// 	let cardValue = this.getCardValue();

	// 	// emit card value
	// 	this.boardItemPicked.next({
	// 		boardItemType: 'card',
	// 		value: cardValue,
	// 		index: this.itemPickedIndex
	// 	});

	// 	this.itemPickedIndex++;
	// }

	onNumberExtracted(evt) {
		if (!this.world.control.isDrawSkipped) {
			// this.showBackground();
		}
		this.resumebar.onNumberExtracted(evt.ball);

		// emit number value evt.ball.ballNumber
		this.boardItemPicked.next({
			boardItemType: 'number',
			value: evt.ball.ballNumber,
			index: this.itemPickedIndex
		});

		this.itemPickedIndex++;
	}

	resize() {
		if (this.tray) {
			this.hideBackground();

			this.tray.resize();
			this.resumebar.setTrayPos(this.tray.slotPosX, this.tray.slotPosY);
			this.resumebar.resize();

			const separatorY = this.resumebar.getHeight() + 20;

			this.ballContainer.setTrayPos(this.tray.slotPosX, this.tray.slotPosY);
			this.ballContainer.upperLimit = separatorY;
			this.ballContainer.resize();

			this.line.y = separatorY;

			// this.cardContainer.resize();
		}
	}

	// private getCardValue(): number {
	// 	let suit: any = this.world.suit;
	// 	switch (suit) {
	// 		case 'hearts':
	// 			return 1;
	// 		case 'clubs':
	// 			return 2;
	// 		case 'spades':
	// 			return 3;
	// 		case 'diamonds':
	// 			return 4;
	// 		default:
	// 			return 0;
	// 	}
	// }

}
