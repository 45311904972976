export class TrayClass extends createjs.Container {
	public slotPosX: number;
	public slotPosY: number;
	private world;
	private imgTray;
	constructor(world) {
		super();
		this.world = world;

		this.imgTray = new createjs.Bitmap(world.loader.getResult('tray'));
		this.addChild(this.imgTray);
		this.imgTray.regX = this.imgTray.getBounds().width / 2;
		this.imgTray.regY = this.imgTray.getBounds().height - 1;
		this.slotPosX = 0;
		this.slotPosY = -1;
	}

	animationIn($time, $delay) {
		this.imgTray.y = this.imgTray.getBounds().height;
		createjs.Tween.get(this.imgTray)
		.wait($delay)
		.to({ y: 0 }, $time);
	}

	resize() {
		this.x = 0.5 * this.world.c_width;
		this.y = this.world.c_height;
		if (this.imgTray) {
			this.imgTray.scaleX = this.imgTray.scaleY = this.world.currentScale;
			this.slotPosX = this.world.c_width / 2;
			this.slotPosY = this.world.c_height - (this.imgTray.getBounds().height - 10) * this.world.currentScale;
		}
	}

}
