import { ControlClass } from './control.class';
export class DrawAnimationWorld {

	private paramAnimationValue: any[] = [];
	private loaderValue: any;
	private cWidthValue: number;
	private cHeightValue: number;
	private delayFixValue: number;
	private delayRandomValue: number;
	private time1ShowAllValue: number;
	private time2ShowAllValue: number;
	private currentScaleValue: number;
	private listExtractValue: any[];
	// private _suit: any;
	private indexExtractValue: number;
	private drawNumbersValue = 5;
	// private _isSecondaryDraw: boolean;
	private isMobileValue: boolean;
	private newVersionValue = true;
	private skipValue = false;
	private drawProcessingValue = true;
	private controlValue: ControlClass;

	constructor() {
		this.paramAnimationValue.push(
			{
				destXMin: -1, // maximum left direction
				destXMax: 2, // maximum right direction
				destXMin_alt: -1, // same for phones
				destXMax_alt: 2, // same for phones
				destYMin: 0.9, // minimum height reached by balls - leave it as it is
				destYMax: 0.35, // maximum height reached by balls - leave it as it is
				delayMin: 20, // random minimum distance between balls
				delayMax: 80, // random maximum distance between balls
				timeMin: 2600, // random maximum total time
				timeMax: 3000 // random maximum total time
			}, {
				destXMin: -0.2,
				destXMax: 1.8,
				destXMin_alt: -0.2,
				destXMax_alt: 1.8,
				destYMin: 1,
				destYMax: 0.35,
				delayMin: 20,
				delayMax: 60,
				timeMin: 2700,
				timeMax: 2900
			},
			{
				destXMin: -0.2,
				destXMax: 2.2,
				destXMin_alt: -0.2,
				destXMax_alt: 2.2,
				destYMin: 0.7,
				destYMax: 0.35,
				delayMin: 20,
				delayMax: 80,
				timeMin: 2600,
				timeMax: 2900
			},
			{
				destXMin: -1,
				destXMax: 2,
				destXMin_alt: -1,
				destXMax_alt: 2,
				destYMin: 0.5,
				destYMax: 0.35,
				delayMin: 20,
				delayMax: 70,
				timeMin: 2700,
				timeMax: 3000
			},
			{
				destXMin: -0.2,
				destXMax: 1.8,
				destXMin_alt: -0.2,
				destXMax_alt: 1.8,
				destYMin: 0.8,
				destYMax: 0.35,
				delayMin: 20,
				delayMax: 40,
				timeMin: 2500,
				timeMax: 2800
			}
			// {
			// 	destXMin: -0.2,
			// 	destXMax: 1.6,
			// 	destXMin_alt: -0.2,
			// 	destXMax_alt: 1.6,
			// 	destYMin: 1.1,
			// 	destYMax: 0.35,
			// 	delayMin: 20,
			// 	delayMax: 40,
			// 	timeMin: 2700,
			// 	timeMax: 3100
			// }
			// {
			// 	destXMin: -0.2,
			// 	destXMax: 1.4,
			// 	destXMin_alt: -0.2,
			// 	destXMax_alt: 1.4,
			// 	destYMin: 0.6,
			// 	destYMax: 0.35,
			// 	delayMin: 20,
			// 	delayMax: 80,
			// 	timeMin: 2400,
			// 	timeMax: 2700
			// },
			// {
			// 	destXMin: -0.2,
			// 	destXMax: 1.2,
			// 	destXMin_alt: -0.2,
			// 	destXMax_alt: 1.2,
			// 	destYMin: 0.8,
			// 	destYMax: 0.30,
			// 	delayMin: 20,
			// 	delayMax: 60,
			// 	timeMin: 2800,
			// 	timeMax: 3100
			// }
		);
		this.controlValue = new ControlClass();
		this.loaderValue = new createjs.LoadQueue(true);
		this.delayFixValue = 0;
		this.cWidthValue = 0;
		this.cHeightValue = 0;
		this.delayRandomValue = 140;
		this.time1ShowAllValue = 0;
		this.time2ShowAllValue = 1600;
		this.currentScaleValue = 1;
		this.listExtractValue = [3, 14, 7, 26, 12, 30, 29, 1];
		// this._suit = 'clubs';
		this.indexExtractValue = 0;
	}

	setIsMobile(isMobile: boolean) {
		this.isMobileValue = isMobile;
	}

	setDrawNumbers() {
		this.drawNumbersValue = 5;
		// this._paramAnimation.pop();
	}

	setCurrentScale(scale: number) {
		this.currentScaleValue = scale;
	}

	setC_width(width: number) {
		this.cWidthValue = width;
	}

	setC_height(height: number) {
		this.cHeightValue = height;
	}

	setIndexExtract(index: number) {
		this.indexExtractValue = index;
	}

	setNewVersion(newVersion: boolean) {
		this.newVersionValue = newVersion;
	}

	setSkip(skip: boolean) {
		this.skipValue = skip;
	}

	setDrawProcessing(process: boolean) {
		this.drawProcessingValue = process;
	}

	get drawProcessing() {
		return this.drawProcessingValue;
	}

	get skip() {
		return this.skipValue;
	}

	get newVersion() {
		return this.newVersionValue;
	}

	// get secondaryDraw() {
	// 	return this._isSecondaryDraw;
	// }

	get indexExtract() {
		return this.indexExtractValue;
	}

	get listExtract() {
		return this.listExtractValue;
	}

	set listExtract(list: any[]) {
		this.listExtractValue = list.map(n => +n );
	}

	get c_width() {
		return this.cWidthValue;
	}

	get c_height() {
		return this.cHeightValue;
	}

	get loader() {
		return this.loaderValue;
	}

	get paramAnimation() {
		return this.paramAnimationValue;
	}

	get delayFix() {
		return this.delayFixValue;
	}

	get delayRandom() {
		return this.delayRandomValue;
	}

	get time1ShowAll() {
		return this.time1ShowAllValue;
	}

	get time2ShowAll() {
		return this.time2ShowAllValue;
	}

	get currentScale() {
		return this.currentScaleValue;
	}

	// get suit() {
	// 	return this._suit;
	// }

	// set suit(suit: any[]) {
	// 	switch ((+suit[0])) {
	// 		case 1:
	// 			this._suit = 'hearts';
	// 			break;
	// 		case 2:
	// 			this._suit = 'clubs';
	// 			break;
	// 		case 3:
	// 			this._suit = 'spades';
	// 			break;
	// 		case 4:
	// 			this._suit = 'diamonds';
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// }

	get drawNumbers() {
		return this.drawNumbersValue;
	}

	get isMobile() {
		return this.isMobileValue;
	}

	get control() {
		return this.controlValue;
	}

}
