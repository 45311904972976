<div class="jackpot">
	{{ 'current-jackpot-component.current-jackpot' | translate }}: <span style="letter-spacing: 1px;" *ngIf="currentJackpot !== undefined">{{ currentJackpot | currency:currencyCode:'symbol':'1.0-0'}}</span>
	<span *ngIf="currentJackpot === undefined">{{ 'current-jackpot-component.tba' | translate }}</span>
</div>
<div class="countdown">
	<div class="value-wrapper">
		{{ 'current-jackpot-component.next-draw' | translate }}
	</div>
	<div class="value-wrapper">
		<div class="value">
			{{ drawClosesInDays | number:'2.0-0' }}
		</div>
		<div class="label">
			{{ 'current-jackpot-component.timer-labels.days' | translate: { 'timeInDays': drawClosesInDays } }}
		</div>
	</div>
	<div class="value-wrapper">
		<div class="value">
			{{ drawClosesInHrs | number:'2.0-0' }}
		</div>
		<div class="label">
			{{ 'current-jackpot-component.timer-labels.hours' | translate: { 'timeInHours': drawClosesInHrs } }}
		</div>
	</div>
	<div class="value-wrapper">
		<div class="value">
			{{ drawClosesInMin | number:'2.0-0' }}
		</div>
		<div class="label">
			{{ 'current-jackpot-component.timer-labels.minutes' | translate: { 'timeInMinutes': drawClosesInMin } }}
		</div>
	</div>
	<div class="value-wrapper">
		<div class="value">
			{{ drawClosesInSec | number:'2.0-0' }}
		</div>
		<div class="label">
			{{ 'current-jackpot-component.timer-labels.seconds' | translate: { 'timeInSeconds': drawClosesInSec } }}
		</div>
	</div>

</div>