import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Utilities, ControllerNames, ApiActions } from '../service-utils';
import { environment } from 'src/environments/environment';
import { Observable, of, observable, forkJoin } from 'rxjs';
import { Draw, GameGroup, GameRule, GameFinancialRule, PastNumberIndicator, DrawnNumberFrequency, DrawnBoardNumber } from '../models';
import { map, catchError, mergeMap, flatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppConfigService } from './app-config.service';

@Injectable({
	providedIn: 'root'
})
export class GameService extends BaseService {
	public gameGroup: GameGroup = new GameGroup();
	public secondaryGameGroup: GameGroup = new GameGroup();
	public isCardGame = false;
	public lastDrawSalesEnd;
	public totalDrawCount = 1000;
	
	hardLimitDraws = 1000;
	drawsLoaded: Draw[];
	secondaryDrawsLoaded: Draw[];
	currentSecondaryDraws: Draw[];
	isSecondary = false;
	
	constructor(
		protected httpClient: HttpClient,
		protected router: Router,
		protected appConfigService: AppConfigService) {
		super(httpClient, appConfigService);

		this.gameGroup.currencyCode = this.appConfigService.currency;
		this.isCardGame = this.appConfigService.gameGroupCode === 'lottohero' || this.appConfigService.gameGroupCode === 'lottohero-5minute';
		this.isSecondary = this.appConfigService.gameGroupCode === 'lottohero';
	}

	getRule(isSecondaryGame = false) {
		if (this.gameGroup.gameRule !== undefined) {
			return of(this.gameGroup.gameRule);
		}

		const url = Utilities.generateApiUri([ControllerNames.GAME, this.appConfigService.gameGroupCode, ApiActions.RULES]);
		return this.get<GameRule>(url).pipe(
			map(response => {
				this.gameGroup.salesEnd = response.primaryGameGroup.salesEnd;
				this.gameGroup.gameRule = response.primaryGameGroup.gameRule;

				if(this.isSecondary && response.secondaryGameGroups) {
					const secondChanceGameGroup =  response.secondaryGameGroups.find(gg => gg.code === "lottohero-secondchance");
					this.secondaryGameGroup.salesEnd = secondChanceGameGroup.salesEnd;
					this.secondaryGameGroup.gameRule = secondChanceGameGroup.gameRule;
				}

				return isSecondaryGame ? this.secondaryGameGroup.gameRule : this.gameGroup.gameRule;
			}),
			catchError(() => {
				this.routeToError();
				return of(this.gameGroup.gameRule);
			})
		);
	}

	getFinancialRules(isSecondaryGame = false) {
		if (this.gameGroup.gameFinancialRules !== undefined) {
			return of(this.gameGroup.gameFinancialRules);
		}

		const url = Utilities.generateApiUri([ControllerNames.GAME, this.appConfigService.gameGroupCode, ApiActions.FINANCIAL_RULES]);
		return this.get<GameFinancialRule[]>(url).pipe(
			map(response => {
				this.gameGroup.gameFinancialRules = response.primaryGameGroup.gameFinancialRules;
				
				if(this.isSecondary && response.secondaryGameGroups) {
					const secondChanceFinancialRules =  response.secondaryGameGroups.find(gg => gg.code === "lottohero-secondchance");
					this.secondaryGameGroup.gameFinancialRules = secondChanceFinancialRules.gameFinancialRules;
				}

				return isSecondaryGame ? this.secondaryGameGroup.gameFinancialRules : this.gameGroup.gameFinancialRules;
			}),
			catchError(() => {
				this.routeToError();
				return of([]);
			})
		);
	}

	getPastDraws(searchParams?: HttpParams): Observable<any> {
		let url = Utilities.generateApiUri([ControllerNames.GAME, this.appConfigService.gameGroupCode, ApiActions.PAST_DRAWS]);

		if (this.isSecondary) {
			let secondaryUrl = Utilities.generateApiUri([ControllerNames.GAME, 'lottohero-secondchance', ApiActions.PAST_DRAWS]);

			return this.get<Draw[]>(secondaryUrl, searchParams).pipe(
				flatMap(resp => {
					this.secondaryDrawsLoaded = this.secondaryDrawsLoaded?.length > 0 ? [...this.deepCopy(resp.resultSet), ...this.secondaryDrawsLoaded] : [...this.deepCopy(resp.resultSet)];
					this.sanitizeDraws(this.secondaryDrawsLoaded);

					this.currentSecondaryDraws = resp.resultSet;
					return this.requestPastDraws(url, searchParams);
				}),
				catchError(() => {
					this.routeToError();
					return of([]);
				})
			);
		}

		return this.requestPastDraws(url, searchParams);
	}

	async getCachedDraws(offset: number, take: number): Promise<Draw[]> {
		if(offset > this.totalDrawCount) {
			return [];
		}

		const availableDraws = this.sliceDrawsByValue(this.drawsLoaded, offset, take);
		if(availableDraws?.length === take) {
			return this.isSecondary ? [...availableDraws, ...this.sliceDrawsByValue(this.secondaryDrawsLoaded, offset, take)] : availableDraws;
		}
		
		const offsetForReq = this.drawsLoaded?.length || 0;
		const takeForReq = offset - offsetForReq + take;
		
		const drawParams = new HttpParams()
			.set('offset', `${offsetForReq}`)
			.set('take', `${takeForReq}`);
		const url = Utilities.generateApiUri([ControllerNames.GAME, this.appConfigService.gameGroupCode, ApiActions.PAST_DRAWS]);
		const draws = await this.get<any>(url, drawParams).toPromise().then(response => {
			return response.resultSet;
		});
		
		if (this.isSecondary) {
			const secondaryUrl = Utilities.generateApiUri([ControllerNames.GAME, 'lottohero-secondchance', ApiActions.PAST_DRAWS]);
			const secondaryDraws = await this.get<any>(secondaryUrl, drawParams).toPromise().then(response => {
				return response.resultSet;
			});

			this.secondaryDrawsLoaded = this.secondaryDrawsLoaded ? [...this.secondaryDrawsLoaded, ...this.deepCopy(secondaryDraws)] : this.deepCopy(secondaryDraws);
			this.sanitizeDraws(this.secondaryDrawsLoaded);
		}
		
		this.drawsLoaded = this.drawsLoaded ? [...this.drawsLoaded, ...this.deepCopy(draws)] : this.deepCopy(draws);
		this.sanitizeDraws(this.drawsLoaded);

		return this.isSecondary ? [...this.sliceDrawsByValue(this.drawsLoaded, offset, take), ...this.sliceDrawsByValue(this.secondaryDrawsLoaded, offset, take)] : this.sliceDrawsByValue(this.drawsLoaded, offset, take);
	}

	getJackpot() {
		const url = Utilities.generateApiUri([ControllerNames.JACKPOTS, this.appConfigService.gameGroupCode]);
		const params = new HttpParams()
			.set('currencyCode', this.appConfigService.currency);
		return this.get(url, params).pipe(
			map(response => {
				this.gameGroup.code = response.attributes.code;
				this.gameGroup.name = response.attributes.name;
				this.gameGroup.jackpotAmount = response.jackpotAmounts.find(x => x.currencyCode === this.appConfigService.currency).amount;

				return response;
			}),
			catchError(() => {
				this.routeToError();
				return of();
			})
		);
	}

	getPastNumberTrends(): Observable<PastNumberIndicator[]> {
		const url = Utilities.generateApiUri([ControllerNames.GAME, this.appConfigService.gameGroupCode, ApiActions.PAST_NUMBER_TRENDS]);
		return this.get<PastNumberIndicator[]>(url).pipe(
			map(response => {
				return response.pastNumbersIndicators;
			}),
			catchError(() => {
				this.routeToError();
				return of([]);
			})
		);
	}

	getDrawnNumberFrequency(year: number): Observable<DrawnBoardNumber[]> {
		const url = Utilities.generateApiUri([ControllerNames.GAME, this.appConfigService.gameGroupCode,
			ApiActions.DRAWN_NUMBERS_FREQUENCY, year.toString()]);

		return this.get<DrawnBoardNumber[]>(url).pipe(
			map(response => {
				return response.boards;
			}),
			catchError(() => {
				this.routeToError();
				return of([]);
			})
		);
	}

	private requestPastDraws(url, searchParams): Observable<Draw[]> {
		return this.get<Draw[]>(url, searchParams).pipe(
			map(response => {
				this.totalDrawCount = response.totalRowCount >= this.hardLimitDraws ? this.hardLimitDraws : response.totalRowCount;

				this.drawsLoaded = this.drawsLoaded?.length > 0 ? [...this.deepCopy(response.resultSet), ...this.drawsLoaded] : [...this.deepCopy(response.resultSet)];
				this.sanitizeDraws(this.drawsLoaded);

				return this.isSecondary ? [...this.deepCopy(response.resultSet), ...this.deepCopy(this.currentSecondaryDraws)] as Draw[] : response.resultSet;
			}),
			catchError(() => {
				this.routeToError();
				return of([]);
			})
		);
	}

	private sanitizeDraws(draws: Draw[]) {
		const noDuplicateDraws = [...new Map(draws.map(obj => [JSON.stringify(obj), obj])).values()];
		const sortedDraws = noDuplicateDraws.sort((firstDraw, secondDraw) => {
			return Date.parse(secondDraw.drawDate) - Date.parse(firstDraw.drawDate);
		});
		draws = sortedDraws;
	}

	private sliceDrawsByValue(draws: Draw[], offset, take) {
		if(!!!draws) return [];

		return JSON.parse(JSON.stringify(draws.slice(offset, offset + take)));
	}

	private deepCopy(obj) {
		return JSON.parse(JSON.stringify(obj));
	}

	private routeToError() {
		this.router.navigate(['/error']);
	}
}
