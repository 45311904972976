import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PastNumberIndicator, GameService } from '../shared';

@Component({
	selector: 'gw-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	gameServiceObservable: Observable<PastNumberIndicator[]>;
	pastNumberIndicators: PastNumberIndicator[] = [];

	constructor(private gameService: GameService,) { }

	ngOnInit(): void {
		this.gameServiceObservable = this.gameService.getPastNumberTrends();
		this.gameServiceObservable.subscribe(data => this.pastNumberIndicators = data);
	}
}
