import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PayTableComponent } from './pay-table/pay-table.component';
import { GameRulesComponent } from './game-rules/game-rules.component';
import { DrawsPageComponent } from './draws-page/draws-page.component';
import { StatisticsPageComponent } from './statistics-page/statistics-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { HomeResolver } from './home.resolver';


const routes: Routes = [
	{ path: '', component: HomeComponent, pathMatch: 'full', resolve: { homeData: HomeResolver } },
	{ path: 'pay-table', component: PayTableComponent, resolve: { homeData: HomeResolver } },
	{ path: 'game-rules', component: GameRulesComponent },
	{ path: 'draws', component: DrawsPageComponent },
	{ path: 'statistics', component: StatisticsPageComponent },
	{ path: 'error', component: ErrorPageComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
