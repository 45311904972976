import { environment } from '../../../environments/environment';

export class Utilities {
	public static generateApiUri(urlSegments: string[]): string {
		const baseUrl = environment.baseServiceUrl;
		return baseUrl + urlSegments.join('/');
	}

	public static formatDrawResults(result: string): any[] {
		if (!result) result = "NotGenerated";
		const formattedBoards = [];
		const boards = result.split('|');
		boards.forEach(board => {
			const boardNums = board.split(',');
			formattedBoards.push(boardNums);
		});

		return formattedBoards;
	}

	public static getLinePrice(amountsList: any[], property: string, currencyCode: string): string {
		for (const amount of amountsList) {
			if (currencyCode === amount.currencyCode) {
				return amount[property];
			}
		}
	}
}
