import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'hgReplaceCurrencyCode'
})

/**
 * Pipe is used so that in case the selected Locale ID does not support the currency code,
 * this is replaced with the correct currency code.
 */
export class ReplaceCurrencyCodePipe implements PipeTransform {
	/**
	 * @param value Formatted currency string
	 * @param currencyCode Currency code to replace
	 */
	transform(value: string, currencyCode: string) {
		if (value !== undefined && value !== null) {
			if (value.includes(currencyCode)) {
				const symbol = this.getCurrencySymbol(currencyCode);
				return value.replace(currencyCode, symbol);
			}

			return value;
		}

	}

	private getCurrencySymbol(currencyCode: string) {
		switch (currencyCode) {
			case 'HGC':
				return 'Ħ';
			case 'NGN':
				return '₦';
			default:
				return currencyCode;
		}
	}
}
