import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'gw-statistics-page',
	templateUrl: './statistics-page.component.html',
	styleUrls: ['./statistics-page.component.scss']
})
export class StatisticsPageComponent implements OnInit {

	constructor(
		private router: Router
	) { }

	ngOnInit(): void {
	}

	closePage() {
		// back to home
		this.router.navigate(['/']);
	}

}
