import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Draw, PastNumberIndicator, DrawResult } from '../shared/models';
import { DisplayContentService, GameService, AppLoaderService } from '../shared/services';
import { HttpParams } from '@angular/common/http';

@Component({
	selector: 'gw-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	homeDraws: Draw[] = [];
	pastNumberIndicators: PastNumberIndicator[] = [];

	constructor(
		private route: ActivatedRoute,
		private displayContentService: DisplayContentService,
		private gameService: GameService,
		private appLoaderService: AppLoaderService
	) { }

	ngOnInit(): void {
		this.appLoaderService.isLoading = false;
		const data = this.route.snapshot.data['homeData'];
		this.homeDraws = data[0];
		this.pastNumberIndicators = data[1];

		this.displayContentService.animationEnd.asObservable().subscribe(() => {
			// refresh past draws
			const drawParams = new HttpParams()
				.set('offset', '0')
				.set('take', '2');
			this.gameService.getPastDraws(drawParams).subscribe(res => {
				this.homeDraws = res;
			});
		});
	}

	loadAnimation() {
		const result = new DrawResult(1234, new Date(), [1, 2, 3, 4, 5]);
		this.displayContentService.createDrawAnimationComponent(result);
		this.displayContentService.drawAnimationInProgress = true;
		window.scrollTo(0, 0);
	}
}
