import { Component, OnInit, AfterContentInit } from '@angular/core';
import { DynamicContent } from '../shared/models';
import { DynamicContentService, AppLangService, AppConfigService } from '../shared/services';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { GameDescriptionContentConfig } from './game-description-content.config';
import { gameGroupNameMap } from '../shared/mapping';

@Component({
	selector: 'gw-game-description',
	templateUrl: './game-description.component.html',
	styleUrls: ['./game-description.component.scss']
})
export class GameDescriptionComponent implements OnInit, AfterContentInit {
	gameDescriptionContentDefined = false;
	gameGroupName: string;

	gameDescriptionHtml: DynamicContent;

	constructor(
		private dynamicContentService: DynamicContentService,
		private appLangService: AppLangService,
		private appConfigService: AppConfigService
	) { }

	ngOnInit(): void {
		this.gameGroupName = gameGroupNameMap[this.appConfigService.gameGroupCode];
	}

	ngAfterContentInit() {
		this.getHtmlContent();
	}

	private getHtmlContent() {
		this.getGameDescriptionData(this.appLangService.selectedLanguage)
			.subscribe((gameDescription: DynamicContent) => {
				this.gameDescriptionHtml = gameDescription;
				this.gameDescriptionContentDefined = true;
			}, (error: HttpErrorResponse) => {
				if (error.status === 404) {
					this.getGameDescriptionData('en').subscribe((gameDescription: DynamicContent) => {
						this.gameDescriptionHtml = gameDescription;
						this.gameDescriptionContentDefined = true;
					}, () => {
						this.gameDescriptionContentDefined = false;
					});
				}
			});
	}

	private getGameDescriptionData(languageCode: string) {
		const gameFolder = GameDescriptionContentConfig.item(this.appConfigService.gameGroupCode);
		const contentPath = `${environment.cdnBaseUrl}assets/game-description-data/${gameFolder}/${languageCode}.json`;
		return this.dynamicContentService.getGameDescriptionContent(contentPath);
	}
}
