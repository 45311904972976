export class ControlClass  {
	isDrawSkippedValue = false;
	isDrawStartedValue = false;
	constructor() {
		//
	}

	stopDrawAnimation(allowFire: number, skipAnimation: boolean) {
		return { allowFire: 0, skipAnimation: true };
	}

	skipDrawAnimation() {
		this.isDrawSkippedValue = true;
	}

	get isDrawSkipped() {
		return this.isDrawSkippedValue;
	}

	get isDrawStarted() {
		return this.isDrawStartedValue;
	}

	setDrawStarted(isDrawStarted: boolean) {
		this.isDrawStartedValue = isDrawStarted;
	}

}
