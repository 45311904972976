import { Resolve, Router } from '@angular/router';

import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { GameService, AppLoaderService } from './shared';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class HomeResolver implements Resolve<any> {
	requested = false;
	initialData = new ReplaySubject<any>();

	constructor(
		private gameService: GameService,
		private appLoaderService: AppLoaderService
	) { }

	resolve(): Observable<any> {
		this.appLoaderService.isLoading = true;
		
		if (!this.requested) {
			this.requested = true;

			const observables = [];
			const drawParams = new HttpParams()
				.set('$offset', '0')
				.set('$take', '2');
			observables.push(this.gameService.getPastDraws(drawParams));
			observables.push(this.gameService.getPastNumberTrends());
			observables.push(this.gameService.getJackpot());
			observables.push(this.gameService.getRule());
			observables.push(this.gameService.getFinancialRules());

			forkJoin(observables).subscribe(data => this.initialData.next(data));
		}

		return this.initialData.pipe(first());
	}
}
