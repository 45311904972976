import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
	name: 'hgWholeNumCurrency'
})

/** Pipe is used to output a currency amount as a whole number if the amount passed does not contain a fractional amount. */
export class WholeNumberCurrencyPipe implements PipeTransform {
	constructor(private currencyPipe: CurrencyPipe) { }

	/**
	 * @param value Currency amount
	 * @param currencyCode Currency code
	 * @param localeID Locale ID
	 */
	transform(value: number, currencyCode: string, localeID: string): string {
		const digitFormat = (value % 1 === 0) ? '1.0-0' : undefined;

		return this.currencyPipe.transform(value, currencyCode, 'symbol', digitFormat, localeID);
	}
}
