import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameService, PayTableItem, AppLangService, AppConfigService, AppLoaderService } from '../shared';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'gw-pay-table',
	templateUrl: './pay-table.component.html',
	styleUrls: ['./pay-table.component.scss']
})
export class PayTableComponent implements OnInit {
	currencyCode: string;
	localeID: string;

	payTableItems: PayTableItem[] = [];
	secondaryPayTableItems: PayTableItem[] = [];

	isSecondary: boolean;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private gameService: GameService,
		private appLangService: AppLangService,
		private appLoaderService: AppLoaderService,
		private appConfigService: AppConfigService
	) { }

	ngOnInit(): void {
		this.isSecondary = this.gameService.isSecondary;
		this.currencyCode = this.appConfigService.currency;
		this.localeID = this.appLangService.selectedLocaleID;
		this.appLoaderService.isLoading = false;

		this.generatePayTableList();
		window.scrollTo(0,0);
	}

	closePage() {
		// back to home
		this.router.navigate(['/']);
	}

	private generatePayTableList() {
		const gameRule = this.gameService.gameGroup.gameRule;
		const financialRules = this.gameService.gameGroup.gameFinancialRules;

		// financialRules.forEach((financialRule, i) => {
		financialRules.find(x => x.gameTier === 'tier-1').prizeCategories.forEach((item, index) => {
			this.payTableItems.push(new PayTableItem(
				index + 1,
				item.amounts,
				item.prizeCategoryID,
				gameRule,
				item.items,
				item.accumulationPercentage,
			));
		});

		if(this.isSecondary) {
			const secondaryGameRule = this.gameService.secondaryGameGroup.gameRule;
			const secondaryFinancialRule = this.gameService.secondaryGameGroup.gameFinancialRules;

			secondaryFinancialRule.find(x => x.gameTier === 'tier-1').prizeCategories.forEach((item, index) => {
				this.secondaryPayTableItems.push(new PayTableItem(
					index + 1,
					item.amounts,
					item.prizeCategoryID,
					secondaryGameRule,
					item.items,
					item.accumulationPercentage,
				));
			});
		}
		// });
	}
}
