export const gameGroupDescription = {
	'lottohero': `An award-winning hourly lottery game with a jackpot set at €1,000,000 Euros round the clock. The Exciting lottery game that turns players into millionaires!
    €1,000,000, every hour, on the hour, 24 hours, 365 days a year. Lotto Hero is the most innovative lottery product on the market, offering hourly jackpots of €1,000,000, a high return to player, and unique gamification features. Based on traditional draw games, players select seven out of thirty-five numbers and a card suit to be in with the chance of winning. Players also have the ability to pay extra to enter their same tickets into a secondary draw where eight numbers are drawn instead of seven further increasing their chances of winning big! As with all lotto games within the Helio Family of Games, players have the option to randomly choose their numbers, buy quick picks, or purchase tickets for future draws.`,
	'lottohero-5minute': 'Lotto Hero',
	'euromillions': 'EuroMillions',
	'eurojackpot': 'EuroJackpot',
	'megamillions': 'MegaMillions',
	'powerball': 'US Powerball',
	'lotto-6aus49': 'Lotto 6aus49',
	'thedailyhero': `An entry-level lottery game which takes place once a day and gives players the chance at scooping a guaranteed jackpot of €10,000,000. Based on a traditional draw game consisting of two separate boards. Players must choose six numbers out of forty-nine from the first board, and one number out of ten from the final board to be in with a chance of winning. As with all lotto games within the Helio Family of Games, players have the option to randomly choose their numbers, buy quick picks, or purchase tickets for future draws.`,
	'heromillions': `Give your players a twice a week shot at guaranteed jackpot of €15,000,000 with draws that offer a highly-competitive price to reward ration. Based on traditional draw games consisting of two separate boards. Players must choose five numbers out of sixty-nine from the first board, and one number out of twenty-six from the final board to be in with a chance of winning. As with all lotto games within the Helio Family of Games, players have the option to randomly choose their numbers, buy quick picks, or purchase tickets for future draws.`,
	'thebighero': `The Daddy of them all in which a whopping guaranteed jackpot of €25,000,000 is up for grabs during its once a week draw. Based on a traditional draw game consisting of a single board. Players must choose five numbers out of ninety to be in with a chance of winning. As with all lotto games within the Helio Family of Games, players have the option to randomly choose their numbers, buy quick picks, or purchase tickets for future draws`,
	'live-6-36': 'Live 6/36',
	'lotto-6-36': `Helio Gaming’s first high speed lottery game offering near instant gratification with a draw every five minutes where players must choose six out of thirty-six numbers to be in with a chance of winning a realtively high jackpot value of a guaranteed €20,000. As with all lotto games within the Helio Family of Games, players have the option to randomly choose their numbers, buy quick picks, or purchase tickets for future draws`,
	'lottorush': `The perfect game for emerging markets to excite customers every hour with a low stakes lottery game with a relatively high Jackpot of a guaranteed €5,000. Based on a traditional draw game consisting of a single board. Players must choose six out of twenty-five numbers to be in with a chance of winning. As with all lotto games within the Helio Family of Games, players have the option to randomly choose their numbers, buy quick picks, or purchase tickets for future draws.`
};
