import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hgSortArray'
})
export class SortArrayPipe implements PipeTransform {

    transform(value: any[], property?: string, direction?: "asc" | "desc") {
        if(direction && direction !== "asc" && direction !== "desc") {
            throw Error('Value for direction can be "asc" or "desc" only!');
        }

        return value.sort((a, b) => {
            if (!direction || direction === "asc") {
                return !!property ? a[property] - b[property] : a - b;
            }

            return !!property ? b[property] - a[property] : b - a;
        });
    }
}