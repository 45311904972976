<h1>{{ 'navigation.pay-table' | translate: { 'isSecondary': isSecondary ? 1 : 0 } }}</h1>
<div style="margin-top: 2rem; position: relative;">
	<button class="close" (click)="closePage()"></button>
	<p *ngIf="payTableItems[0].isDynamicPrize" [innerHTML]="'pay-table.jackpot-slider-intro' | translate | gwSafeHtml">
		
	</p>
	<div class="content-wrapper">
		<div style="font-weight: 700;font-size: 1.5rem;" *ngIf="isSecondary">Primary Draw</div>
		<div class="table">
			<ng-container *ngFor="let item of payTableItems">
				<div class="row">
					<div class="column id">{{ item.gamePrizeID }}</div>
					<div class="column lucky-num">
						<div class="draw-circles-wrapper">
							<ng-container *ngFor="let boardNumbers of item.boardsNumbers; let i = index">
								<span *ngIf="i > 0" class="plus">+</span>
								<ng-container *ngFor="let number of boardNumbers">
									<span class="circle" [class.selected]="number"></span>
								</ng-container>
							</ng-container>
						</div>
					</div>
					<div *ngIf="!item.isFreeTicketChance" class="column prize-amount">
						<ng-container *ngIf="item.isDynamicPrize">
							{{ item.prizeAmount | hgCurrencyAmount: 'minPrizeAmount':currencyCode | hgWholeNumCurrency:currencyCode:localeID | hgReplaceCurrencyCode:currencyCode }}
							<span>+</span>
						</ng-container>
						<ng-container *ngIf="!item.isDynamicPrize">
							{{ item.prizeAmount | hgCurrencyAmount: 'prizeAmount':currencyCode | hgWholeNumCurrency:currencyCode:localeID | hgReplaceCurrencyCode:currencyCode }}
						</ng-container>
					</div>
					<div *ngIf="item.isFreeTicketChance" class="column free-ticket-chance">
						{{ 'pay-table.free-bet-chance' | translate }}
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div *ngIf="isSecondary" class="content-wrapper" style="margin-top: 35px;">
		<div style="font-weight: 700;font-size: 1.5rem;">Secondary Draw</div>
		<div class="table">
			<ng-container *ngFor="let item of secondaryPayTableItems">
				<div class="row">
					<div class="column id">{{ item.gamePrizeID }}</div>
					<div class="column lucky-num">
						<div class="draw-circles-wrapper">
							<ng-container *ngFor="let boardNumbers of item.boardsNumbers; let i = index">
								<span *ngIf="i > 0" class="plus">+</span>
								<ng-container *ngFor="let number of boardNumbers">
									<span class="circle" [class.selected]="number"></span>
								</ng-container>
							</ng-container>
						</div>
					</div>
					<div *ngIf="!item.isFreeTicketChance" class="column prize-amount">
						<ng-container *ngIf="item.isDynamicPrize">
							{{ item.prizeAmount | hgCurrencyAmount: 'minPrizeAmount':currencyCode | hgWholeNumCurrency:currencyCode:localeID | hgReplaceCurrencyCode:currencyCode }}
							<span>+</span>
						</ng-container>
						<ng-container *ngIf="!item.isDynamicPrize">
							{{ item.prizeAmount | hgCurrencyAmount: 'prizeAmount':currencyCode | hgWholeNumCurrency:currencyCode:localeID | hgReplaceCurrencyCode:currencyCode }}
						</ng-container>
					</div>
					<div *ngIf="item.isFreeTicketChance" class="column free-ticket-chance">
						{{ 'pay-table.free-bet-chance' | translate }}
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>