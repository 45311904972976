/**
 * Utility class with useful Mathematical functions
 */
export class MathUtilities {
	/**
	 * Calculates factorial of a given number
	 * @param n A non-negative number to calculate factorial with
	 * @throws Will throw an error if n is less than 0
	 * @return Factorial of the given number
	 */
	public static factorial(n: number): number {
		if (n < 0) {
			// TEXT TO TRANSLATE
			throw new console.error('Factorial Number cannot be negative');
		}

		let result = 1;

		if (n === 1 || n === 0) {
			return result;
		}

		for (let i = n; i > 1; i--) {
			result *= i;
		}

		return result;
	}

	/**
	 * Calculates the amount all possible combinations in a given set ignoring the order
	 * @param itemsInSet Number denoting the number of items in the set
	 * @param itemsInSubset Number denoting the number of items to be selected in the set
	 * @throws Will throw an error if itemsInSet is smaller than itemsInSubset
	 * @throws Will throw an error if itemsInSubset is 0
	 * @returns The amount of combinations
	 */
	public static combinations(itemsInSet: number, itemsInSubset: number): number {
		if (itemsInSet < itemsInSubset) {
			// TEXT TO TRANSLATE
			throw new console.error('Combination Set Cannot Be Smaller Than Subset');
		}

		if (itemsInSubset < 0) {
			// TEXT TO TRANSLATE
			throw new console.error('Combination Subset Must Be Zero Or Larger');
		}

		const result = this.factorial(itemsInSet) / (this.factorial(itemsInSubset) * this.factorial(itemsInSet - itemsInSubset));

		return Math.round(result);
	}

	/**
	 * Calculates the amount of all possible ordered combinations in a given set
	 * @param itemsInSet Number denoting the number of items in the set
	 * @param itemsInSubset Number denoiting the number of items to be selected in the set
	 * @throws Will throw an error if itemsInSet is smaller than itemsInSubset
	 * @throws Will throw an error if itemsInSubset is 0
	 * @returns The amount of permutations
	 */
	public static permutations(itemsInSet: number, itemsInSubset: number): number {
		if (itemsInSet < itemsInSubset) {
			// TEXT TO TRANSLATE
			throw new console.error('Combination Set Cannot Be Smaller Than Subset');
		}

		if (itemsInSubset < 0) {
			// TEXT TO TRANSLATE
			throw new console.error('Combination Subset Must Be Zero Or Larger');
		}

		const result = this.factorial(itemsInSet) / this.factorial(itemsInSet - itemsInSubset);

		return result;
	}

	/**
	 * Generates a random number between two values - Min and Max are inclusive
	 * @param minNumber Minimum random number
	 * @param maxNumber Maximum random number
	 * @returns A random number between minNumber and maxNumber
	 */
	public static randomNumber(minNumber: number, maxNumber: number): number {
		minNumber = Math.ceil(minNumber);
		maxNumber = Math.floor(maxNumber);
		return Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
	}

	/**
	 * Generates a list of random numbers
	 * @param listLength Amount of numbers to be returned
	 * @param minNumber Minimum number of the numbers to be generated
	 * @param maxNumber Maximum number of the numbers to be generated
	 * @param startList Array of numbers to add numbers to
	 * @throws Will throw an error if listLength is 0 or negative
	 * @returns Array with the generated random numbers
	 */
	public static randomList(
		listLength: number,
		minNumber: number,
		maxNumber: number,
		startList: number[] = []): number[] {

		if (listLength <= 0) {
			// TEXT TO TRANSLATE
			throw new console.error('Random List Length Cannot Be Zero Or Negative');
		}

		const tempArray: number[] = startList;
		while (tempArray.length < listLength) {
			const randomNumber = this.randomNumber(minNumber, maxNumber);
			tempArray[tempArray.length] = randomNumber;
		}

		return tempArray;
	}

	/**
	 * Generates a list of unique random numbers
	 * @param listLength Amount of numbers to be returned
	 * @param minNumber Minimum number of the numbers to be generated
	 * @param maxNumber Maximum number of the numbers to be generated
	 * @param startList Array of numbers to add numbers to
	 * @throws Will thrown an error if listLength is 0 or negative
	 * @throws Will thrown an error if maxNumber is smaller than listLength
	 * @returns Array with the generated random numbers
	 */
	public static unqiueRandomList(
		listLength: number,
		minNumber: number,
		maxNumber: number,
		startList: number[] = []): number[] {

		if (listLength <= 0) {
			// TEXT TO TRANSLATE
			throw new console.error('Random List Length Cannot Be Zero Or Negative');
		}

		if (listLength > maxNumber) {
			// TEXT TO TRANSLATE
			throw new console.error('Random List Length Cannot Be Larger Than Max Num');
		}

		const tempArray: number[] = startList;
		while (tempArray.length < listLength) {
			const randomNumber = this.randomNumber(minNumber, maxNumber);
			if (tempArray.indexOf(randomNumber) > -1) {
				continue;
			}
			tempArray[tempArray.length] = randomNumber;
		}

		return tempArray;
	}
}
