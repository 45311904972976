import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from '../service-utils';

@Pipe({
	name: 'hgCurrencyAmount'
})

/** Pipe is used to get the currency amount from an array by currency code */
export class CurrencyAmountPipe implements PipeTransform {

	/**
	 * @param value Array containing the currency amounts
	 * @param propertyType String representing the object property containing the amount
	 * @param currencyCode Currency code of the amount to be selected
	 */
	transform(value: any[], propertyType: string, currencyCode: string): number {
		return +Utilities.getLinePrice(value, propertyType, currencyCode);
	}
}
