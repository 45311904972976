import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { WindowResizeEmit } from '../models';


@Injectable()
export class WindowResizeService {
	resizeObservable: Observable<WindowResizeEmit>;

	private currentWindowValue: WindowResizeEmit;

	constructor() { }

	initWindowResize() {
		this.initWindowSize();
		this.windowResizeListener();
	}

	get currentWindow() {
		return this.currentWindowValue;
	}

	private initWindowSize() {
		return this.currentWindowValue = {
			width: window.innerWidth,
			height: window.innerHeight,
			isMobile: this.checkIfIsMobile()
		};
	}

	private windowResizeListener() {
		this.resizeObservable = fromEvent(window, 'resize')
			.pipe(
				map((event: Event) => {
					this.currentWindowValue = {
						event,
						width: window.innerWidth,
						height: window.innerHeight,
						isMobile: this.checkIfIsMobile()
					};

					return this.currentWindowValue;
				}),
				debounceTime(300)
			);
	}

	private checkIfIsMobile() {
		return window.innerWidth <= 887;
	}
}
