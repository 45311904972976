<div class="dropdown-wrapper" (clickOutside)="onClickedOutside()" [class.opened]="isOpened" [class.has-selection]="listSelection.length > 0">
	<div #selectorDiv class="selector" (click)="toggleList()">
		<div class="selector-content">
			<ng-container *ngIf="selectedItemTemplate === undefined">
				{{ selectorText }}
			</ng-container>
			<ng-container *ngIf="selectedItemTemplate !== undefined">
				<ng-container *ngTemplateOutlet="selectedItemTemplate"></ng-container>
			</ng-container>
			<div *ngIf="!customSelectorIcon" class="icon">
				<svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 20">
					<path d="M0 0h32L16 20z" fill="#fff"/>
				</svg>
			</div>
			<ng-content *ngIf="customSelectorIcon" select="[selector-icon]"></ng-content>
		</div>
	</div>
	<ng-container *ngIf="!showScrollbar">
		<ul #dropdownDiv [hidden]="!isOpened" class="dropdown">
			<li *ngFor="let item of list" (click)="toggleSelection(item)" class="item" [class.selected]="isSelected(item)">
				<div *ngIf="multipleSelection" class="item-checkbox"></div>
				<div class="item-text">
					<ng-container *ngIf="itemsTemplate === undefined">
						{{ item[labelProp] }}
					</ng-container>
					<ng-container *ngIf="itemsTemplate !== undefined">
						<ng-container *ngTemplateOutlet="itemsTemplate; context: {$implicit: item}"></ng-container>
					</ng-container>
				</div>
			</li>
		</ul>
	</ng-container>
	<ng-container *ngIf="showScrollbar">
		<ul #dropdownScrollDiv [hidden]="!isOpened" class="dropdown" [style.height]="listHeightPX">
			<perfect-scrollbar class="container ps-show-always">
				<li *ngFor="let item of list" (click)="toggleSelection(item)" class="item" [class.selected]="isSelected(item)">
					<div *ngIf="multipleSelection" class="item-checkbox"></div>
					<div class="item-text">
						<ng-container *ngIf="itemsTemplate === undefined">
							{{ item[labelProp] }}
						</ng-container>
						<ng-container *ngIf="itemsTemplate !== undefined">
							<ng-container *ngTemplateOutlet="itemsTemplate; context: {$implicit: item}"></ng-container>
						</ng-container>
					</div>
				</li>
			</perfect-scrollbar>
		</ul>
	</ng-container>
</div>
