import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

export class CardContainerClass extends createjs.Container {

	public onPlaySound: Observable<void>;

	private container;
	private world;
	private backCard;
	private suit;
	private active;
	private playSound: Subject<void> = new Subject<void>();

	constructor(world) {
		super();
		this.world = world;

		this.container = new createjs.Container();
		this.addChild(this.container);

		this.backCard = new createjs.Bitmap(world.loader.getResult('card'));
		this.backCard.regX = this.backCard.getBounds().width / 2;
		this.backCard.regY = this.backCard.getBounds().height / 2;
		this.container.addChild(this.backCard);
		this.suit = new createjs.Bitmap(world.loader.getResult(world.suit));
		this.suit.regX = this.suit.getBounds().width / 2;
		this.suit.regY = this.suit.getBounds().height / 2;
		this.container.addChild(this.suit);
		this.suit.visible = false;
		this.active = 1;
		this.visible = false;

		this.onPlaySound = this.playSound.asObservable();
	}

	animationIn() {
		if (this.active) {
			createjs.Tween.removeTweens(this.container);
			this.suit.visible = false;
			this.container.scaleX = this.container.scaleY = this.world.currentScale;

			this.visible = true;

			this.container.x = this.world.c_width / 2;

			let time = 400;
			let time2 = 1000;
			this.container.y = this.world.c_height * 2;

			if (this.world.control.isDrawSkipped) {
				time = 10;
				time2 = 200;
				this.container.alpha = 0;
				this.showBackground();
				this.cardExtracted();
			} else {
			createjs.Tween.get(this.container)
				.to({ y: this.world.c_height / 2 }, time * 4, createjs.Ease.getPowInOut(2.2))
				.to({ scaleX: 0 }, time, createjs.Ease.getPowInOut(2.2))
				.call(this.showBackground, null, this)
				.to({ scaleX: this.world.currentScale }, time, createjs.Ease.getPowInOut(2.2))
				.wait(time2)
				.call(this.cardExtracted, null, this);
			}

		}
	}

	showBackground() {
		if (environment.soundEnabled) {
			this.playSound.next();
		}
		this.suit.visible = true;
		if (!this.world.control.isDrawSkipped) {
			const evt = new CustomEvent('showBackground');
			this.dispatchEvent(evt);
		}

	}

	cardExtracted() {
		this.active = 0;
		const evt = new CustomEvent('cardExtracted');
		(evt as any).card = this.container;
		this.dispatchEvent(evt);
	}

	resize() {
		if (this.visible) {
			this.animationIn();
		}
	}
}
