<div id="container">
	<div class="background-artwork {{ gameGroupCode }}">
	</div>
	<div class="background-overlay">
	</div>
	<div class="content">
		<div class="content-inner">
			<div id="header">
				<gw-header></gw-header>
			</div>
			<div class="page">
				<div *ngIf="isLoading" class="loading">
					<div class="section"></div>
					<div></div>
					<div></div>
					<div></div>
					<div class="section"></div>
					<div></div>
					<div></div>
					<div></div>
					<div class="section"></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div [style.display]="isLoading ? 'none' : 'block'">
					<router-outlet></router-outlet>
				</div>
				<div id="animation-wrapper" class="play-area-overlayed-content" [@loadAnimation]="animationHidden">
					<ng-template #animationHost gwComponentHost></ng-template>
				</div>
			</div>
		</div>
		<gw-footer></gw-footer>
	</div>
	<ng-template #overlayHost gwComponentHost></ng-template>
</div>