import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AppConfig } from '../models';

/** Service used to load the JSON application config file and to access its properties */
@Injectable()
export class AppConfigService {
	private config: AppConfig;

	constructor(private http: HttpClient) {
	}

	public loadAppConfig(): Promise<{}> {
		return new Promise((resolve) => {
			const url = `app-config/app-config.json`;

			this.http.get(url)
				.subscribe(config => {
					this.setAppConfigObj(config);

					localStorage.setItem('helioAppConfig', JSON.stringify(this.config));
					resolve();
				}, error => {
						throw new Error('Could not load app config');
				}),
				catchError((e) => {
					return throwError(e);
				});
		});
	}

	public get gameGroupCode(): string {
		return this.appConfig.gameGroupCode;
	}

	public get apiKey(): string {
		return this.appConfig.apiKey;
	}

	public get currency(): string {
		return this.appConfig.currency;
	}

	private get appConfig(): AppConfig {
		return this.config;
	}

	private setAppConfigObj(config: any): void {
		this.config = new AppConfig(
			config.gameGroupCode,
			config.apiKey,
			config.currency
		);
	}
}
