export enum SoundPath {
	TAB_CLICK = 'assets/sound/tab-click.mp3',
	MAIN_BG_MUSIC = 'assets/sound/bg-music.mp3',
	PICK_NUMBER = 'assets/sound/pick-number.mp3',
	UNPICK_NUMBER = 'assets/sound/unpick-number.mp3',
	QUICKPICK = 'assets/sound/quickpick.mp3',
	PLACE_BET = 'assets/sound/place-bet.mp3',
	NORMAL_WIN_BG_MUSIC = 'assets/sound/normal-win-bg-music.mp3',
	BIG_WIN_BG_MUSIC = 'assets/sound/big-win-bg-music.mp3',
	JACKPOT_WIN_BG_MUSIC = 'assets/sound/jackpot-win-bg-music.mp3',
	DRAW_BG_MUSIC = 'assets/sound/draw-bg-music.mp3',
	DRAW_SPIN = 'assets/sound/draw-spin.mp3',
	BALLS_FALLING = 'assets/sound/balls-falling.mp3',
	BET_CANCELLED = 'assets/sound/bet-cancelled.mp3',
	CROWD_CHEERING = 'assets/sound/crowd-cheering.mp3',
	FIREWORK_BLAST = 'assets/sound/firework-blast.mp3',
	FIREWORK_WHISTLE = 'assets/sound/fireworks-whistle.mp3',
	JACKPOT_MONEY_FOUNTAIN = 'assets/sound/jackpot-money-fountain.mp3',
	JACKPOT_MONEY_FOUNTAIN_CHIME = 'assets/sound/jackpot-money-fountain-chime.mp3',
	NUMBER_MATCHED = 'assets/sound/number-matched.mp3',
	NUMBER_NOT_MATCHED = 'assets/sound/number-not-matched.mp3'
}
