import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'hgReplacePlaceholders'
})

/**
 * Pipe is used to replace placeholders in a string.
 * This is useful in case you have a piece of text that should contain dynamic amounts
 */
export class ReplacePlaceholdersPipe implements PipeTransform {
	private regex: RegExp = /({{(.*?)}})/gi;

	/**
	 * @param text Text containing the placeholders
	 * @param placeholderValuesObject A key-value pair object. Key should be the placeholder replaced,
	 * with the value being the text to be inserted instead of the placeholder.
	 */
	transform(text: string, placeholderValuesObject: object): string {
		let match = null;
		const stringsToReplace: string[] = [];
		const objectKeys: string[] = [];

		do {
			match = this.regex.exec(text);
			if (match !== null) {
				stringsToReplace.push(match[1]);
				objectKeys.push(match[2]);
			}
		} while (match !== null);

		objectKeys.forEach((key, i) => {
			text = text.replace(stringsToReplace[i], placeholderValuesObject[key]);
		});

		return text;
	}
}
