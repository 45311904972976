export const gameGroupNameMap = {
	'lottohero': 'Lotto Hero',
	'lottohero-5minute': 'Lotto Hero',
	'euromillions': 'EuroMillions',
	'eurojackpot': 'EuroJackpot',
	'megamillions': 'MegaMillions',
	'powerball': 'US Powerball',
	'lotto-6aus49': 'Lotto 6aus49',
	'thedailyhero': 'The Daily Hero',
	'heromillions': 'Hero Millions',
	'thebighero': 'The Big Hero',
	'live-6-36': 'Live 6/36',
	'lotto-6-36': 'Lotto 6/36',
	'lottorush': 'Lotto Rush'
};
