import { GameRuleBoard } from './game-rule-board.model';
import { GamePrizeBoard } from './game-prize-board.model';
import { GameFinancialRulePrizeCategoryAmount } from './game-financial-rule-prize-category-amount.model';
import { GameRule } from './game-rule.model';
import { GameFinancialRulePrizeCategoryItem } from './game-financial-rule-prize-category-item.model';

export class PayTableItem {
	public boardsGameRules: GameRuleBoard[] = [];
	public boardsNumbers: number[][] = [];
	public boardsMatchNumbers: number[] = [];

	public cardsBoard: GamePrizeBoard;
	public cardsTest: number[];
	public isFreeTicketChance = false;
	public isDynamicPrize = false;

	constructor(
		public gamePrizeID: number,
		public prizeAmount: GameFinancialRulePrizeCategoryAmount[],
		public prizeCategoryID: number,
		public gameRule: GameRule,
		public items: GameFinancialRulePrizeCategoryItem[],
		public accumulationPercentage: number,
		public nonMonetaryPrize?: string
	) {
		this.isDynamicPrize = (this.accumulationPercentage !== undefined);
		this.saveBoardItems();
		this.setFreeTicketChance();
	}

	private saveBoardItems() {
		const gameRulePrizeCategory = this.gameRule.prizeCategories.find(temp => temp.prizeCategoryID === this.prizeCategoryID);

		this.gameRule.boards.forEach(tempBoard => {
			const prizeCategory = gameRulePrizeCategory.boards.find(tempPrizeCategoryBoard =>
				tempPrizeCategoryBoard.boardNum === tempBoard.boardNum);

			this.boardsGameRules.push(tempBoard);

			const matchNumbers = tempBoard.numColumns - (tempBoard.numColumns - prizeCategory.correctColumns);
			this.boardsMatchNumbers.push(matchNumbers);

			const numbersArray = Array(tempBoard.numColumns).fill(1, 0, matchNumbers);
			this.boardsNumbers.push(numbersArray);
		});
	}

	private setFreeTicketChance() {
		if (this.items !== undefined) {
			if (this.items.length > 0) {
				if (this.items[0].gamePrizeItemTypeID === 2) {
					this.isFreeTicketChance = true;
				}
			}
		}
	}
}
