<h1>{{ 'game-description.title' | translate }} <span style="text-transform: uppercase;">{{ gameGroupName }}</span></h1>
<p class="content">
	<ng-container *ngIf="gameDescriptionContentDefined">
		<ng-container *ngIf="gameDescriptionHtml?.sections.length > 0">
			<div *ngFor="let section of gameDescriptionHtml?.sections">
				<h3 *ngIf="section.title !== null && section.title !== ''">{{ section.title }}</h3>
				<div *ngIf="section.content !== null"
					[innerHTML]="section.content | gwSafeHtml"></div>
			</div>
		</ng-container>
	</ng-container>
</p>
<div class="links">
	<a [routerLink]="['/pay-table']">{{ 'navigation.pay-table' | translate: { 'isSecondary': 0 } }}</a>
	<a [routerLink]="['/game-rules']">{{ 'navigation.game-rules' | translate }}</a>
</div>