import { Observable, Subject } from 'rxjs';

import { BallClass } from './ball.class';
import { BallInterface } from './ball.interface';
import { environment } from '../../../environments/environment';

export class ShowAllClass extends createjs.Container {
	public showCollapsed;
	public onPlaySound: Observable<void>;
	public active;

	private amountOfBalls = 25;
	private balls;
	private numColumn;
	private content;
	private delayFix;
	private delayRandom;
	private time1ShowAll;
	private time2ShowAll;
	private cWidth;
	private cHeight;
	private world: any;
	private playSound: Subject<void> = new Subject<void>();

	constructor() {
		super();
		this.active = 0;
		this.balls = [];
		this.numColumn = 5;
		this.showCollapsed = 0;

		this.onPlaySound = this.playSound.asObservable();
	}

	init(world: any) {
		this.world = world;
		this.delayFix = world.delayFix;
		this.delayRandom = world.delayRandom;
		this.time1ShowAll = world.time1ShowAll;
		this.time2ShowAll = world.time2ShowAll;
		this.cHeight = world.c_height;
		this.cWidth = world.c_width;

		this.content = new createjs.Container();
		this.addChild(this.content);
		for (let i = 1; i <= this.amountOfBalls; i++ ) {
			const ball = new BallClass(i);
			this.addChild(ball);
			this.balls.push(ball);
		}
		this.active = 1;
		this.resize();
	}

	public resize() {
		if (this.cWidth > this.cHeight) {
			this.numColumn = 5;
		} else {
			this.numColumn = 5;
		}
		this.collapseFirst();
	}

	collapseFirst(loaded: boolean = false) {
		if (environment.soundEnabled && loaded) {
			this.playSound.next();
		}
		createjs.Tween.removeTweens(this);
		if ( this.showCollapsed) {
			createjs.Tween.get(this)
			.wait(this.delayFix + this.delayRandom + this.time1ShowAll + this.time2ShowAll)
			.call(this.end);
		}

		$(this.balls).each( (index, item) => {
			const ballItem = item as BallInterface;
			ballItem.resize();
			createjs.Tween.removeTweens (item);
			ballItem.x = this.getPosX(index);
			ballItem.y = this.getPosY(index);

			if (this.showCollapsed) {
				const destY = ballItem.y + this.cHeight * 1.2;

				const delay = this.delayFix + Math.random() * this.delayRandom;

				createjs.Tween.get(item).wait(delay)
				.to({ y: destY}, this.time1ShowAll + this.time2ShowAll, createjs.Ease.getPowIn(2.2));
			}
		});
	}

	end() {
		// end of all balls.
		this.world.control.setDrawStarted(true);
		this.active = 0;
		const evt = new CustomEvent('end');
		this.dispatchEvent(evt);
	}

	getPosX(index) {
		const interval = this.cWidth * 0.9 / this.numColumn;
		return(this.cWidth * 0.05 + (0.5 + index % this.numColumn) * interval);
	}

	getPosY(index) {
		const interval = this.cHeight * 0.9 / (this.amountOfBalls / this.numColumn);
		return (this.cHeight * 0.05 + (0.5 + Math.floor(index / this.numColumn)) * interval);
	}

}
